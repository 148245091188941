import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';

import {EventsByYear} from './events_utils';

import styles from './EventsAccordion.module.scss';

interface Props {
  defaultExpanded: boolean;
  eventsByYear: EventsByYear;
}
export function EventsAccordion(props: Props) {
  return (
    <>
      <Accordion
        className={styles.Table}
        defaultExpanded={props.defaultExpanded}
        key={props.eventsByYear.year}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>{props.eventsByYear.year}</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper} className={styles.Table}>
            <Table>
              <TableBody>
                {props.eventsByYear.goldEvents.map((goldEvent) => {
                  const goldSlug = goldEvent.slug;
                  const silverSlug = `${goldSlug}-silver`;
                  const silverEvent =
                    props.eventsByYear.silverEvents[silverSlug];
                  return (
                    <TableRow key={goldEvent.id}>
                      <TableCell>
                        <Link to={`/events/${goldSlug}`}>{goldEvent.name}</Link>
                      </TableCell>
                      <TableCell>
                        {silverEvent !== undefined ? (
                          <Link to={`/events/${silverSlug}`}>Silver</Link>
                        ) : (
                          ''
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
