import Avatar from '@mui/joy/Avatar';
import TrendingDown from '@mui/icons-material/TrendingDown';
import TrendingUp from '@mui/icons-material/TrendingUp';
import Tooltip from '@mui/joy/Tooltip';

import {getScoreTrend} from './qualifiers_score_trend_utils';
import {scoreToVirusScore} from './utils/virus_score';

import styles from './QualifierScoreTrendGraphql.module.scss';

interface Props {
  score: number;
  previousQualifierScore: number | undefined;
}

export function QualifierScoreTrend(props: Props) {
  const scoreTrend = getScoreTrend(props.score, props.previousQualifierScore);
  const previousQualifierScore = props.previousQualifierScore ?? 0;
  return scoreTrend === 0 ? (
    <></>
  ) : (
    <Tooltip
      size="sm"
      title={`Previous qualifier: ${previousQualifierScore} = ${scoreToVirusScore(
        previousQualifierScore,
      )}`}>
      <Avatar
        size="sm"
        variant="soft"
        color={scoreTrend < 1 ? 'danger' : 'success'}>
        {scoreTrend < 1 ? (
          <TrendingDown className={styles.Icon} />
        ) : (
          <TrendingUp className={styles.Icon} />
        )}
      </Avatar>
    </Tooltip>
  );
}
