import {useQuery} from '@apollo/client';
import Typography from '@mui/material/Typography';

import {organizeByYear} from './events_utils';
import {EventsAccordion} from './EventsAccordion';
import {gql} from './types/gql';

const QUERY = gql(`
  query GetEvents {
    events {
      id
      name
      slug
      startDate
    }
  }
`);

export function Events() {
  const {loading, error, data} = useQuery(QUERY);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error || !data) {
    return <div>Error: {error?.message ?? 'Unknown'}</div>;
  }

  const eventsByYears = organizeByYear(data.events);

  return (
    <>
      <Typography variant="h3" component="h1">
        Events
      </Typography>
      {eventsByYears.map((eventsByYear, index) => (
        <EventsAccordion
          eventsByYear={eventsByYear}
          defaultExpanded={index === 0}
          key={eventsByYear.year}></EventsAccordion>
      ))}
    </>
  );
}
