import React from "react";
import socketIOClient from "socket.io-client";

import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ArrowLeft from "@mui/icons-material/ArrowLeft";
import ArrowRight from "@mui/icons-material/ArrowRight";

import { Bracket } from "./Bracket";
import Standings from "./Standings.js";
import BracketDialog from "./BracketDialog";
import IfAdmin from "./IfAdmin";
import Qualifiers from "./Qualifiers";
import { scoreToVirusScore } from "./utils/virus_score";
import {apiFetch} from "./utils/api_fetch";

import styles from "./Event.module.scss";

class Event extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {},
      prevEvent: null,
      nextEvent: null,
      sisterEvent: null,
      seeds: [],
      games: [],
      rounds: [],
      qualifiers: [],
      qualifyingAttempts: [],
      prevQualifyingAttempts: {},
      bestQualifyingAttempts: {},
      playerMap: {},
      isLoaded: false,
      isBracketDialogOpen: false,
    };
  }

  componentDidMount() {
    this.updateEvent();

    this.socket = socketIOClient();

    this.socket.on('matchUpdate', ({match}) => {
      const rounds = this.state.rounds;
      if (rounds.length === 0) {
        return;
      }
      rounds.forEach((round, roundIndex) => {
        round.matches.forEach((oldMatch, matchIndex) => {
          if (oldMatch.id === match.id) {
            match.games = oldMatch.games;
            rounds[roundIndex].matches[matchIndex] = match;
          }
        });
      });
      this.setState({rounds});
    });
    const updateGame = (game, type) => {
      const rounds = this.state.rounds;
      if (rounds.length === 0) {
        return;
      }
      rounds.forEach((round, roundIndex) => {
        round.matches.forEach((match, matchIndex) => {
          if (game.match_id && game.match_id !== match.id) {
            return;
          }
          const gameIndex = match.games.findIndex((oldGame) => oldGame.id === game.id);
          if (type !== 'CREATE' && gameIndex === -1) {
            return;
          }
          addScoreNotationToGame(game, round);
          switch (type) {
            case 'DELETE':
              rounds[roundIndex].matches[matchIndex].games.splice(gameIndex, 1);
              break;
            case 'CREATE':
              rounds[roundIndex].matches[matchIndex].games.push(game);
              break;
            case 'UPDATE':
              rounds[roundIndex].matches[matchIndex].games[gameIndex] = game;
              break;
            default:
              break;
          }
        });
      });
      this.setState({rounds});
    };
    this.socket.on('gameDelete', ({id}) => {
      updateGame({id}, 'DELETE');
    });
    this.socket.on('gameCreate', ({game}) => {
      updateGame(game, 'CREATE');
    });
    this.socket.on('gameUpdate', ({game}) => {
      updateGame(game, 'UPDATE');
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.idOrSlug !== prevProps.match.params.idOrSlug) {
      this.updateEvent();
    }
  }

  componentWillUnmount() {
    this.socket.disconnect();
  }

  updateEvent() {
    return Promise.all([
      apiFetch("events"),
      apiFetch(`events/${this.props.match.params.idOrSlug}`),
      apiFetch(`events/${this.props.match.params.idOrSlug}/seeds`),
      apiFetch(`events/${this.props.match.params.idOrSlug}/rounds`),
      apiFetch(`events/${this.props.match.params.idOrSlug}/qualifying-attempts`),
      apiFetch(`qualifying-attempts`),
      apiFetch("players"),
      Standings.getPlayerMapCurrentYear(),
    ]).then(
      ([
        eventsResponse,
        eventResponse,
        seedsResponse,
        roundsResponse,
        qualifyingAttemptsResponse,
        allQualifyingAttemptsResponse,
        playersResponse,
        standings,
      ]) => {
        Promise.all(
          roundsResponse.map((round) => apiFetch(`rounds/${round.id}/matches`))
        ).then((matchesResponse) => {
          Promise.all(
            matchesResponse
              .flat()
              .map((match) => apiFetch(`matches/${match.id}/games`))
          ).then((gamesResponse) => {
            const playerMap = new Map(
              playersResponse.map((player) => [player.id, player])
            );
            for (const qualifyingAttempt of allQualifyingAttemptsResponse) {
              qualifyingAttempt.scoreNotation = scoreToVirusScore(
                qualifyingAttempt.score, true
              );
            }
            let qualifyingAttemptsMap = createQualifierMap(qualifyingAttemptsResponse);
            
            const isSilver = eventResponse.name.match(/silver/i);
            let sisterEvent = null;
            let qualPromise = Promise.resolve(null);
            let seedPromise = Promise.resolve(null);
            for (const e of eventsResponse.flat()) {
              if (!isSilver && e.slug === eventResponse.slug + '-silver') {
                sisterEvent = e;
              }
              if (isSilver && e.slug + '-silver' === eventResponse.slug) {
                sisterEvent = e;
                qualPromise = apiFetch(`events/${e.id}/qualifying-attempts`);
                seedPromise = apiFetch(`events/${e.id}/seeds`);
              }
            }
            
            const gamesMap = {};
            for (const game of gamesResponse.flat()) {
              if (!gamesMap[game.match_id]) {
                gamesMap[game.match_id] = [];
              }
              gamesMap[game.match_id].push(game);
            }
            const eventsMap = {};
            for (const e of eventsResponse.flat()) {
              eventsMap[e.id] = e;
            }
            if (isSilver) {
              eventsResponse = eventsResponse.filter(e => e.name.match(/silver/i));
            } else {
              eventsResponse = eventsResponse.filter(e => !e.name.match(/silver/i));
            }
            const matchMap = {};
            for (const match of matchesResponse.flat()) {
              match.games = (gamesMap[match.id] || []).sort(
                (a, b) => a.game_number - b.game_number
              );
              if (!matchMap[match.round_id]) {
                matchMap[match.round_id] = [];
              }
              matchMap[match.round_id].push(match);
            }
            for (const roundId of Object.keys(matchMap)) {
              matchMap[roundId].sort((a, b) => a.match_number - b.match_number);
            }
            const populatedRounds = roundsResponse
              .flat()
              .map((round) => {
                round.matches = matchMap[round.id] || [];
                return round;
              })
              .sort((a, b) => a.order - b.order);
            for (const round of populatedRounds) {
              for (const match of round.matches) {
                for (let game of match.games) {
                  addScoreNotationToGame(game, round);
                }
              }
            }
            eventsResponse.sort((a, b) =>
              a.start_date < b.start_date ? -1 : 1
            );
            const eventIndex = eventsResponse.findIndex(
              (e) => e.id === eventResponse.id
            );
            const prevEvent =
              eventIndex === 0 ? null : eventsResponse[eventIndex - 1];
              
            qualPromise.then((secondQualifyingAttemptsResponse) => { seedPromise.then((secondSeedsResponse) => {
              let sliceIndex = 0;
              if (secondQualifyingAttemptsResponse !== null) {
                qualifyingAttemptsMap = createQualifierMap(secondQualifyingAttemptsResponse);
                qualifyingAttemptsResponse = secondQualifyingAttemptsResponse;
                sliceIndex = 16;
              }
              
              const sortQuals = (a, b) => {
                const dropDiff = a.is_drop - b.is_drop;
                if (dropDiff === 0) {
                  const scoreDiff = b.score - a.score;
                  if (scoreDiff === 0) {
                    return (
                      a.time_to_last_level_seconds -
                      b.time_to_last_level_seconds
                    );
                  }
                  return scoreDiff;
                }
                return dropDiff;
              }

              let qualifiers;
              if (isSilver && seedsResponse.length > 0) {
                const silverPlayers = seedsResponse.map((s) => s.player_id);
                qualifiers = Object.values(qualifyingAttemptsMap)
                  .filter((qual) => silverPlayers.includes(qual.player_id))
                  .filter((qual) => !qual.is_drop)
                  .sort(sortQuals).slice(0,16);
              } else if (eventResponse.name.match(/championship/i)) {
                const currentYear = parseInt(eventResponse.start_date.replace(/([0-9]{4}).*/, "$1"));
                qualifiers = Array.from(standings.values())
                .filter((p) => (p.events >= 3 || (currentYear === 2020 && p.events >= 2)))
                .sort(
                  (a, b) => {
                    return a.rank - b.rank;
                  }
                ).slice(isSilver ? 16 : 0);
                qualifiers.forEach((p) => {
                  p.is_drop = false;
                  p.player_id = p.id;
                });
              } else if (isSilver && secondSeedsResponse.length > 0) {
                const goldPlayers = secondSeedsResponse.map((s) => s.player_id);
                qualifiers = Object.values(qualifyingAttemptsMap).sort(sortQuals);
                qualifiers = qualifiers.filter((qual) => !qual.is_drop)
                  .filter((qual) => !goldPlayers.includes(qual.player_id))
                  .slice(0,16);
              } else {
                qualifiers = Object.values(qualifyingAttemptsMap).sort(sortQuals).slice(sliceIndex);
              }

              this.setState({
                event: eventResponse,
                prevEvent: prevEvent,
                nextEvent:
                  eventIndex === eventsResponse.length - 1
                    ? null
                    : eventsResponse[eventIndex + 1],
                sisterEvent,
                qualifiers: qualifiers,
                qualifyingAttempts: qualifyingAttemptsResponse,
                prevQualifyingAttempts: new Map(
                  allQualifyingAttemptsResponse
                    .filter((a) => eventsMap[a.event_id].start_date < eventResponse.start_date)
                    .sort((a, b) => a.attempt - b.attempt)
                    .sort((a, b) => eventsMap[a.event_id].start_date.localeCompare(eventsMap[b.event_id].start_date))
                    .map((attempt) => [attempt.player_id, attempt])
                ),
                bestQualifyingAttempts: new Map(
                  allQualifyingAttemptsResponse
                    .filter((a) => eventsMap[a.event_id].start_date < eventResponse.start_date)
                    .filter((a) => a.attempt === 2 || !allQualifyingAttemptsResponse.some((b) => b.attempt === 2 && b.event_id === a.event_id && b.player_id === a.player_id))
                    .sort((a,b) => a.score - b.score)
                    .map((a) => [a.player_id, a])
                ),
                rounds: populatedRounds,
                seeds: seedsResponse.sort((a, b) => a.seed - b.seed),
                playerMap,
                isLoaded: true,
              });
            });
          })});
        });
      }
    );
  }

  setBracketDialogOpen(isOpen) {
    this.setState({ isBracketDialogOpen: isOpen });
  }

  createSilverEvent(event) {
    apiFetch("events", {
      method: "POST",
      body: JSON.stringify({
        "name": event.name.match(/championship/i) ? event.name.slice(0,4) + " Silver Championship" : event.name + " Silver",
        "start_date": event.start_date.slice(0,10),
        "slug": event.slug + "-silver"
      }),
    }).then((response) => {
      this.updateEvent();
    });
  }

  render() {
    const {
      event,
      prevEvent,
      nextEvent,
      sisterEvent,
      rounds,
      seeds,
      qualifiers,
      qualifyingAttempts,
      prevQualifyingAttempts,
      bestQualifyingAttempts,
      playerMap,
      isLoaded,
      isBracketDialogOpen,
    } = this.state;
    const { user, showSnackbar } = this.props;
    if (!isLoaded) {
      return <div>Loading...</div>;
    }
    const currentYear = parseInt(event.start_date.replace(/([0-9]{4}).*/, "$1"));
    let bracket;
    let bracketButtonText;
    let bracketButtonIcon;
    if (seeds.length > 0) {
      bracket = (
        <Bracket
          seeds={seeds}
          rounds={rounds}
          playerMap={playerMap}
          user={user}
          updateEvent={() => this.updateEvent()}
          showSnackbar={showSnackbar}
        />
      );
      bracketButtonText = "Edit bracket";
      bracketButtonIcon = <EditIcon />;
    } else {
      bracket = (
        <Bracket
          seeds={qualifiers.filter((qualifier) => !qualifier.is_drop)}
          playerMap={playerMap}
          title="Provisional Bracket"
          user={user}
          updateEvent={() => this.updateEvent()}
          showSnackbar={showSnackbar}
        />
      );
      bracketButtonText = "Create bracket";
      bracketButtonIcon = <AddIcon />;
    }
    const bracketButton = (
      <IfAdmin user={user}>
        <Button
          className={styles.BracketButton}
          variant="outlined"
          color="primary"
          startIcon={bracketButtonIcon}
          onClick={() => this.setBracketDialogOpen(true)}
        >
          <span className={styles.BracketButtonText}>{bracketButtonText}</span>
        </Button>
      </IfAdmin>
    );
    const isSilver = event.name.match(/silver/i);
    const isChampionship = event.name.match(/championship/i);
    const qualifiersEl = isChampionship ? (<></>) : (
      <Qualifiers
        qualifiers={qualifiers}
        qualifyingAttempts={qualifyingAttempts}
        prevQualifyingAttempts={prevQualifyingAttempts}
        bestQualifyingAttempts={bestQualifyingAttempts}
        playerMap={playerMap}
        showSnackbar={showSnackbar}
        updateEvent={() => this.updateEvent()}
        user={user}
        event={event}
      />
    );
    let prevEventLink;
    if (prevEvent === null) {
      prevEventLink = <></>;
    } else {
      prevEventLink = (
        <>
          <Tooltip title={prevEvent.name}>
            <Link
              to={"/events/" + (prevEvent.slug ? prevEvent.slug : prevEvent.id)}
              className={styles.Link}
            >
              <ArrowLeft className={styles.NavigationIcons} />
            </Link>
          </Tooltip>
        </>
      );
    }
    let nextEventLink;
    if (nextEvent === null) {
      nextEventLink = <></>;
    } else {
      nextEventLink = (
        <Tooltip title={nextEvent.name}>
          <Link
            to={"/events/" + (nextEvent.slug ? nextEvent.slug : nextEvent.id)}
            className={styles.Link}
          >
            <ArrowRight className={styles.NavigationIcons} />
          </Link>
        </Tooltip>
      );
    }
    let sisterEventLink;
    let createSilverButton;
    if (!isSilver && sisterEvent === null && currentYear >= 2021) {
      createSilverButton = (
        <IfAdmin user={user}>
          <Button
            className={styles.BracketButton}
            variant="outlined"
            color="primary"
            startIcon=<AddIcon />
            onClick={() => this.createSilverEvent(event)}
          >
            <span className={styles.BracketButtonText}>Create Silver Event</span>
          </Button>
        </IfAdmin>
      );
    }
    if (sisterEvent !== null) {
      sisterEventLink = (
        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href={"/events/" + sisterEvent.slug}>{isSilver ? 'Gold' : 'Silver'} Bracket</a></>
      );
    }
    const bracketDialog = isBracketDialogOpen ? (
      <BracketDialog
        open={isBracketDialogOpen}
        setOpen={(isOpen) => this.setBracketDialogOpen(isOpen)}
        qualifiers={qualifiers.filter((qualifier) => !qualifier.is_drop)}
        seeds={seeds}
        rounds={rounds}
        playerMap={playerMap}
        eventIdOrSlug={this.props.match.params.idOrSlug}
        isSilver={isSilver}
        updateEvent={() => this.updateEvent()}
        showSnackbar={showSnackbar}
      />
    ) : (
      ""
    );
    return (
      <div className={styles.EventContainer}>
        <div className={styles.Header}>
          <div className={styles.Title}>
            {prevEventLink}
            {nextEventLink}
            <Typography variant="h3" className={styles.TitleText}>
              {event.name}
            </Typography>
            {sisterEventLink}
            {createSilverButton}
          </div>
          {bracketButton}
        </div>
        {bracketDialog}
        {bracket}
        {qualifiersEl}
      </div>
    );
  }
}

function createQualifierMap(qualifyingAttemptsResponse) {
  const qualifyingAttemptsMap = {};
  for (const qualifyingAttempt of qualifyingAttemptsResponse.sort(
    (a, b) => a.attempt - b.attempt
  )) {
    qualifyingAttempt.scoreNotation = scoreToVirusScore(
      qualifyingAttempt.score, true
    );
    if (qualifyingAttemptsMap[qualifyingAttempt.player_id]) {
      const lastScore =
        qualifyingAttemptsMap[qualifyingAttempt.player_id].score;
      const lastScoreNotation =
        qualifyingAttemptsMap[qualifyingAttempt.player_id]
          .scoreNotation;
      const lastQualifyingAttempts =
        qualifyingAttemptsMap[qualifyingAttempt.player_id]
          .qualifyingAttempts;
      qualifyingAttemptsMap[
        qualifyingAttempt.player_id
      ] = qualifyingAttempt;
      qualifyingAttemptsMap[
        qualifyingAttempt.player_id
      ].lastScore = lastScore;
      qualifyingAttemptsMap[
        qualifyingAttempt.player_id
      ].lastScoreNotation = lastScoreNotation;
      qualifyingAttemptsMap[
        qualifyingAttempt.player_id
      ].qualifyingAttempts = lastQualifyingAttempts;
      qualifyingAttemptsMap[
        qualifyingAttempt.player_id
      ].qualifyingAttempts.push(qualifyingAttempt);
    } else {
      qualifyingAttemptsMap[
        qualifyingAttempt.player_id
      ] = qualifyingAttempt;
      qualifyingAttemptsMap[
        qualifyingAttempt.player_id
      ].qualifyingAttempts = [qualifyingAttempt];
    }
  }
  return qualifyingAttemptsMap;
}

function addScoreNotationToGame(game, round) {
  if (game.player_1_score !== null) {
    game.player_1_score_notation = scoreToVirusScore(
      game.player_1_score,
      false /* shouldPad */,
      round.level_start
    );
  }
  if (game.player_2_score !== null) {
    game.player_2_score_notation = scoreToVirusScore(
      game.player_2_score,
      false /* shouldPad */,
      round.level_start
    );
  }
}

export default Event;
