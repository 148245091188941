import {DateTime} from 'luxon';

import {GetEventsQuery} from './types/graphql';

export type Event = GetEventsQuery['events'][0];

export interface EventsByYear {
  year: number;
  goldEvents: Event[];
  silverEvents: Record<string, Event>;
}

function createEventsByYear(
  year: number,
  goldEvents: Event[],
  silverEvents: Record<string, Event>,
): EventsByYear {
  return {
    year,
    goldEvents,
    silverEvents,
  };
}

export function organizeByYear(events: Event[]): EventsByYear[] {
  const eventsByYears: EventsByYear[] = [];
  let goldEvents: Event[] = [];
  let silverEvents: Record<string, Event> = {};
  let prevYear: number | undefined = undefined;
  [...events]
    .sort(
      (a, b) =>
        DateTime.fromSQL(b.startDate).toMillis() -
        DateTime.fromSQL(a.startDate).toMillis(),
    )
    .forEach((event) => {
      const year = DateTime.fromSQL(event.startDate ?? '').toUTC().year;
      if (prevYear !== undefined && year !== prevYear) {
        eventsByYears.push(
          createEventsByYear(prevYear, goldEvents, silverEvents),
        );
        goldEvents = [];
        silverEvents = {};
      }
      if (!event.name.match(/silver/i)) {
        goldEvents.push(event);
      } else {
        silverEvents[event.slug] = event;
      }
      prevYear = year;
    });
  if (prevYear !== undefined) {
    eventsByYears.push(createEventsByYear(prevYear, goldEvents, silverEvents));
  }
  return eventsByYears;
}
