export function virusScoreToScore(
  virusScore: string,
  levelStart: number = 0,
): number {
  if (!virusScore) {
    return 0;
  }
  const [level, virusesRemaining] = splitVirusScore(virusScore);
  return Math.max(
    0,
    ((level ?? 0) + 1) * ((level ?? 0) + 2) * 2 -
      (virusesRemaining ?? 0) -
      levelStart * (levelStart + 1) * 2,
  );
}

export function splitVirusScore(virusScore: string): number[] {
  return virusScore
    .replace(/[()]/, ':')
    .split(':')
    .map((part) => parseInt(part));
}

export function scoreToVirusScore(
  score: number,
  shouldPad: boolean = false,
  levelStart: number = 0,
): string {
  if (
    isNaN(score) ||
    score < 0 ||
    levelStart < 0 ||
    (score === 0 && levelStart !== 0)
  ) {
    return '';
  }
  let totalScore = 0;
  let level = 0;
  for (let i = levelStart; i <= 20; i++) {
    level = i;
    totalScore += (level + 1) * 4;
    if (totalScore > score) break;
  }
  const padLength = shouldPad ? 2 : 0;
  return `${('' + level).padStart(padLength, '0')}(${(
    '' +
    (totalScore - score)
  ).padStart(padLength, '0')})`;
}

export function isValidVirusScore(virusScore: string): boolean {
  if (virusScore === '') {
    return true;
  }
  if (!virusScore.match(/\d{2}\(\d{2}\)/)) {
    return false;
  }
  const [level, virusesRemaining] = splitVirusScore(virusScore);
  return (
    (level ?? 0) >= 0 &&
    (level ?? 0) <= 20 &&
    (virusesRemaining ?? 0) >= 0 &&
    (virusesRemaining ?? 0) <= ((level ?? 0) + 1) * 4
  );
}
