import React from "react";

import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";


import IfAdmin from "./IfAdmin";
import Image from "./Image";
import QualifierDialog from "./QualifierDialog";
import {isAdmin} from "./utils/is_admin";
import { secondsToTime } from "./utils/time";

import styles from "./Qualifiers.module.scss";

class Qualifiers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isQualifierDialogOpen: false,
      qualifyingAttempt: null,
    };
  }

  setQualifierDialogOpen(isOpen) {
    this.setState({
      isQualifierDialogOpen: isOpen,
    });
  }

  editQualifyingAttempt(qualifyingAttempt) {
    this.setState({
      qualifyingAttempt,
      isQualifierDialogOpen: true,
    });
  }

  updateQualifiers() {
    return this.props.updateEvent();
  }

  render() {
    const {
      qualifiers,
      qualifyingAttempts,
      prevQualifyingAttempts,
      bestQualifyingAttempts,
      playerMap,
      showSnackbar,
      user,
      event,
    } = this.props;
    const { isQualifierDialogOpen, qualifyingAttempt } = this.state;
    let seed = 1;
    const qualifierDialog = isQualifierDialogOpen ? (
      <QualifierDialog
        open={isQualifierDialogOpen}
        closeDialog={() => this.setQualifierDialogOpen(false)}
        qualifyingAttempt={qualifyingAttempt}
        qualifyingAttempts={qualifyingAttempts}
        playerMap={playerMap}
        event={event}
        updateQualifiers={() => this.updateQualifiers()}
        showSnackbar={showSnackbar}
      />
    ) : (
      ""
    );
    const createButton = event.name.match(/silver/i) ? "" : (
      <IfAdmin user={user}>
        <Button
          className={styles.AddButton}
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => this.editQualifyingAttempt(null)}
        >
          <span className={styles.Text}>Add qualifying attempt</span>
        </Button>
      </IfAdmin>
    );
    return (
      <div className={styles.QualifiersContainer}>
        <div className={styles.Title}>
          <Typography variant="h5">Qualifiers</Typography>
          {createButton}
        </div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="right">Seed</TableCell>
                <TableCell>Player</TableCell>
                <TableCell></TableCell>
                <TableCell align="center" colSpan="2">
                  Score
                </TableCell>
                <TableCell align="right">Time to Last Level</TableCell>
                <TableCell align="right">Previous Attempt</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {qualifiers.map((qualifier) => {
                const player = playerMap.get(qualifier.player_id);
                const playerImage = player.twitch_user ? (
                  <Image
                    src={player.twitch_user.profile_image_url}
                    className={styles.PlayerImage}
                  />
                ) : (
                  ""
                );
                let lastScoreNotation = "";
                let scoreNotation = "";
                const lastAttempt =
                  qualifier.lastScore !== undefined
                    ? qualifier.qualifyingAttempts[0]
                    : null;
                const currentAttempt =
                  qualifier.lastScore !== undefined
                    ? qualifier.qualifyingAttempts[1]
                    : qualifier.qualifyingAttempts[0];
                if (isAdmin(user) && !event.name.match(/silver/i)) {
                  if (lastAttempt) {
                    lastScoreNotation = (
                      <a
                        href="#edit-qualifying-attempt"
                        onClick={(e) => {
                          e.preventDefault();
                          this.editQualifyingAttempt(lastAttempt);
                        }}
                      >
                        {qualifier.lastScoreNotation}
                      </a>
                    );
                  }
                  scoreNotation = (
                    <a
                      href="#edit-qualifying-attempt"
                      onClick={(e) => {
                        e.preventDefault();
                        this.editQualifyingAttempt(currentAttempt);
                      }}
                    >
                      {qualifier.scoreNotation}
                    </a>
                  );
                } else {
                  if (lastAttempt) {
                    lastScoreNotation = qualifier.lastScoreNotation;
                  }
                  scoreNotation = qualifier.scoreNotation;
                }
                let trendSymbol;
                const prevQual = prevQualifyingAttempts.get(
                  qualifier.player_id
                );
                if (prevQual === undefined) {
                  trendSymbol = <></>;
                } else if (qualifier.score > prevQual.score) {
                  trendSymbol = (
                    <>
                      <Tooltip title={"Previous qualifier: " + prevQual.score}>
                        <ExpandLessIcon className={styles.TrendIconUp} />
                      </Tooltip>
                    </>
                  );
                } else if (qualifier.score < prevQual.score) {
                  trendSymbol = (
                    <>
                      <Tooltip title={"Previous qualifier: " + prevQual.score}>
                        <ExpandMoreIcon className={styles.TrendIconDown} />
                      </Tooltip>
                    </>
                  );
                }
                let pbBadge = <></>
                const bestQual = bestQualifyingAttempts.get(
                  qualifier.player_id
                );
                if (bestQual !== undefined && qualifier.score >= bestQual.score) {
                  pbBadge = <>
                    <Tooltip title={"Previous PB: " + bestQual.scoreNotation + " = " + bestQual.score}>
                      <Chip style={{"backgroundColor": "#fffde7"}} size="small" label="PB" avatar={<Avatar src="/badge.png" style={{"backgroundColor": "#fdd835"}}/>}/>
                    </Tooltip>
                  </>
                } else if (bestQual === undefined) {
                  pbBadge = <>
                    <Chip style={{"backgroundColor": "#fffde7"}} size="small" label="PB" avatar={<Avatar src="/badge.png" style={{"backgroundColor": "#fdd835"}}/>}/>
                  </>
                }
                return (
                  <TableRow key={qualifier.id} className={qualifier.is_drop ? styles.IsDrop : ''}>
                    <TableCell align="right">{qualifier.is_drop ? '' : seed++}</TableCell>
                    <TableCell>
                      <div className={styles.PlayerInfo}>
                        {playerImage}
                        <Link to={"/players/" + player.gamer_tag}>
                          {player.gamer_tag}
                        </Link>
                      </div>
                    </TableCell>
                    <TableCell>
                      {pbBadge}
                    </TableCell>
                    <TableCell align="right">{scoreNotation}</TableCell>
                    <TableCell className={styles.NoWrap} align="left">
                      {qualifier.score}
                      {trendSymbol}
                    </TableCell>
                    <TableCell align="right">
                      {secondsToTime(qualifier.time_to_last_level_seconds)}
                    </TableCell>
                    <TableCell align="right">{lastScoreNotation}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {qualifierDialog}
      </div>
    );
  }
}

export default Qualifiers;
