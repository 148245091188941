import Chip from "@mui/material/Chip";

import { ReactComponent as TwitchIcon } from "./twitch.svg";

import styles from "./TwitchChip.module.scss";

const TwitchChip = ({ player, className }) => {
  if (!player.twitch_user) {
    return "";
  }

  const handleClick = () => {
    const win = window.open(
      `https://twitch.tv/${player.twitch_user.display_name}`,
      "_blank"
    );
    if (win.opener) {
      win.opener = null;
    }
  };

  return (
    <Chip
      icon={<TwitchIcon className={styles.TwitchIcon} />}
      label={player.twitch_user.display_name}
      className={`${styles.TwitchChip} ${className}`}
      onClick={handleClick}
    />
  );
};

export default TwitchChip;
