export function timeToSeconds(time: string): number {
  if (!time) {
    return 0;
  }
  const [minutes, seconds] = time.split(':').map((part) => parseInt(part));
  return (minutes ?? 0) * 60 + (seconds ?? 0);
}

export function secondsToTime(
  seconds: number,
  shouldPad: boolean = false,
): string {
  if (!seconds) {
    return '';
  }
  const padLength = shouldPad ? 2 : 0;
  return `${('' + Math.floor(seconds / 60)).padStart(padLength, '0')}:${(
    '' + Math.floor(seconds % 60)
  ).padStart(2, '0')}`;
}

export function isValidTime(time: string): boolean {
  return time === '' || !!time.match(/\d{2}:\d{2}/);
}
