import Cleave from "cleave.js/react";
import TextField from "@mui/material/TextField";

const CleaveTextField = ({ inputRef, ...props }) => (
  <Cleave
    {...props}
    htmlRef={inputRef}
    options={{ time: true, timePattern: ["m", "s"] }}
  />
);

export const TimeTextField = (props) => (
  <TextField
    InputProps={{ inputComponent: CleaveTextField }}
    placeholder="mm:ss"
    InputLabelProps={{ shrink: true }}
    {...props}
  />
);
