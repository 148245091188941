import {DateTime, Duration} from 'luxon';
import {
  getScore,
  isDrop,
  Player,
  Qualifier,
  QualifyingAttempt,
} from './event_utils';
import {secondsToTime} from './utils/time';
import {scoreToVirusScore} from './utils/virus_score';

type PlayerQualifyingAttempt = Player['qualifyingAttempts'][0];

export interface RenderableQualifier {
  key: string;
  seed: string;
  player: Player;
  isPersonalBestScore: boolean;
  previousPersonalBestScore: number | undefined;
  virusScore: string;
  score: number;
  previousQualifierScore: number | undefined;
  timeToLastLevel: string;
  previousAttemptVirusScore: string;
  isDrop: boolean;
}

export function getRenderableQualifiers(
  qualifiers: Qualifier[],
  startDate: string,
): RenderableQualifier[] {
  return qualifiers.map((qualifier, index) =>
    getRenderableQualifier(qualifier, index, startDate),
  );
}

function getRenderableQualifier(
  qualifier: Qualifier,
  index: number,
  startDate: string,
): RenderableQualifier {
  const qualifyingAttempt = getQualifyingAttempt(
    qualifier.qualifyingAttemptMap,
  );
  const previousQualifyingAttempt = getPreviousQualifyingAttempt(
    qualifier.qualifyingAttemptMap,
  );
  const score = qualifyingAttempt?.score ?? 0;
  const previousAttemptScore = previousQualifyingAttempt?.score;
  const localIsDrop = isDrop(qualifier);
  const localIsPersonalBestScore = isPersonalBestScore(
    qualifier,
    DateTime.fromSQL(startDate).toMillis(),
  );
  const previousPersonalBestScore = getPersonalBestScore(
    qualifier,
    DateTime.fromSQL(startDate).minus(Duration.fromMillis(1)).toMillis(),
  );
  return {
    key: qualifier.player.id,
    seed: localIsDrop ? 'DROP' : `${index + 1}`,
    player: qualifier.player,
    isPersonalBestScore: localIsPersonalBestScore,
    previousPersonalBestScore,
    virusScore: scoreToVirusScore(score, true /* shouldPad */),
    score,
    previousQualifierScore: getPreviousQualifierScore(
      qualifier,
      DateTime.fromSQL(startDate).toMillis(),
    ),
    timeToLastLevel: secondsToTime(
      qualifyingAttempt?.timeToLastLevelSeconds ?? 0,
    ),
    previousAttemptVirusScore: previousAttemptScore
      ? scoreToVirusScore(previousAttemptScore, true /* shouldPad */)
      : '',
    isDrop: localIsDrop,
  };
}

function getQualifyingAttempt<T>(
  qualifyingAttemptMap: Record<number, T>,
): T | undefined {
  return qualifyingAttemptMap[2] ?? qualifyingAttemptMap[1];
}

function getPreviousQualifyingAttempt(
  qualifyingAttemptMap: Record<number, QualifyingAttempt>,
): QualifyingAttempt | undefined {
  return qualifyingAttemptMap[2] ? qualifyingAttemptMap[1] : undefined;
}

function getPreviousQualifierScore(
  qualifier: Qualifier,
  startDate: number,
): number | undefined {
  return getQualifierScores(qualifier).filter(
    (qualifierScore) => qualifierScore.startDate < startDate,
  )[0]?.score;
}

function isPersonalBestScore(qualifier: Qualifier, startDate: number): boolean {
  const score = getScore(qualifier);
  const personalBestScore = getPersonalBestScore(qualifier, startDate) ?? 0;
  return score >= personalBestScore;
}

function getPersonalBestScore(
  qualifier: Qualifier,
  startDate: number,
): number | undefined {
  const scores = getQualifierScores(qualifier)
    .filter((qualifierScore) => qualifierScore.startDate < startDate)
    .map((qualifierScore) => qualifierScore.score);
  return scores.length > 0 ? Math.max(...scores) : undefined;
}

interface QualifierScore {
  score: number;
  startDate: number;
}

/**
 * Returns all of a player's qualifying attempt scores and the date of the event
 * in which they occurred, sorted by date of the event in descending order.
 */
function getQualifierScores(qualifier: Qualifier): QualifierScore[] {
  const eventIdQualifyingAttemptMap: Record<
    string,
    Record<number, PlayerQualifyingAttempt>
  > = {};
  qualifier.player.qualifyingAttempts.forEach((qualifyingAttempt) => {
    const eventId = qualifyingAttempt.event.id;
    if (eventIdQualifyingAttemptMap[eventId] === undefined) {
      eventIdQualifyingAttemptMap[eventId] = {};
    }
    eventIdQualifyingAttemptMap[eventId]![qualifyingAttempt.attempt] =
      qualifyingAttempt;
  });
  return Object.values(eventIdQualifyingAttemptMap)
    .map((qualifyingAttemptMap) => {
      const qualifyingAttempt = getQualifyingAttempt(qualifyingAttemptMap);
      return {
        score: qualifyingAttempt?.score ?? 0,
        startDate: qualifyingAttempt?.event.startDate
          ? DateTime.fromSQL(qualifyingAttempt.event.startDate).toMillis()
          : 0,
      };
    })
    .sort((a, b) => b.startDate - a.startDate);
}
