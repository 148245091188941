import React from "react";
import { Link } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import Image from "./Image";
import {apiFetch} from "./utils/api_fetch";
import { secondsToTime } from "./utils/time";

import styles from "./Standings.module.scss";

import { scoreToVirusScore } from "./utils/virus_score";
let levelVirusCounts = { "6-9": 136, "8-11": 168, "10-14": 260, "13-17": 320, "4-7": 104, "10-13": 200, "14-17": 264};

const Achievements = {
	GOLD_WINNER: 'GOLD_WINNER',
	GOLD_RUNNER_UP: 'GOLD_RUNNER_UP',
	GOLD_SEMIS: 'GOLD_SEMIS',
	GOLD_TOP_8: 'GOLD_TOP_8',
	GOLD_TOP_16: 'GOLD_TOP_16',
	SILVER_WINNER: 'SILVER_WINNER',
	SILVER_RUNNER_UP: 'SILVER_RUNNER_UP',
	SILVER_SEMIS: 'SILVER_SEMIS'
};

const pointSystem = {
	2020: {
		'GOLD_WINNER': 4,
		'GOLD_RUNNER_UP': 2
	},
	2021: {
		'GOLD_WINNER': 4,
		'GOLD_RUNNER_UP': 2,
		'GOLD_SEMIS': 1
	},
	2022: {
		'GOLD_WINNER': 6,
		'GOLD_RUNNER_UP': 4,
		'GOLD_SEMIS': 3,
		'GOLD_TOP_8': 2,
		'GOLD_TOP_16': 1,
		'SILVER_WINNER': 1
	},
	2023: {		// lookups will default to this year for future years
		'GOLD_WINNER': 25,
		'GOLD_RUNNER_UP': 18,
		'GOLD_SEMIS': 12,
		'GOLD_TOP_8': 8,
		'GOLD_TOP_16': 4,
		'SILVER_WINNER': 4,
		'SILVER_RUNNER_UP': 2,
		'SILVER_SEMIS': 1		
	},
};

const Sort = {
  SORT_DEFAULT: 0,
  SORT_POINTS: 1,
  SORT_CLEAR_RATE: 2,
  SORT_ROUND: 3,
  SORT_PLAYER: 4,
  SORT_EVENTS: 5,
  SORT_WINS: 6,
  SORT_WIN_PCT: 7,
  SORT_QUAL_AVE: 8,
  SORT_POINT_RATE: 9,
};

const SortDescending = new Set([Sort.SORT_POINTS, Sort.SORT_EVENTS, Sort.SORT_WINS, Sort.SORT_WIN_PCT, Sort.SORT_QUAL_AVE, Sort.SORT_POINT_RATE]);

const SortOrder = {
  SORT_ORDER_ASC: 0,
  SORT_ORDER_DESC: 1,
};

function getPointsDescription(player, pointYear) {
  return (
    <>
      <Typography variant="h6">
        {player.gamer_tag} total points: {player.points}
      </Typography>
      {player.firstPlace ? (
        <Typography variant="body2">
          ({player.firstPlace}) 1st place ({pointSystem[pointYear][Achievements.GOLD_WINNER]} points) = {player.firstPlace * pointSystem[pointYear][Achievements.GOLD_WINNER]}
        </Typography>
      ) : (
        ""
      )}
      {player.secondPlace ? (
        <Typography variant="body2">
          ({player.secondPlace}) 2nd place ({pointSystem[pointYear][Achievements.GOLD_RUNNER_UP]} points) = {player.secondPlace * pointSystem[pointYear][Achievements.GOLD_RUNNER_UP]}
        </Typography>
      ) : (
        ""
      )}
      {player.semis && pointSystem[pointYear][Achievements.GOLD_SEMIS] !== undefined ? (
        <Typography variant="body2">
          ({player.semis}) Semifinals ({pointSystem[pointYear][Achievements.GOLD_SEMIS]} point{pointSystem[pointYear][Achievements.GOLD_SEMIS] > 1 ? "s" : ""}) = {player.semis * pointSystem[pointYear][Achievements.GOLD_SEMIS]}
        </Typography>
      ) : (
        ""
      )}
      {player.eliteEight && pointSystem[pointYear][Achievements.GOLD_TOP_8] !== undefined ? (
        <Typography variant="body2">
          ({player.eliteEight}) Round 2 ({pointSystem[pointYear][Achievements.GOLD_TOP_8]} point{pointSystem[pointYear][Achievements.GOLD_TOP_8] > 1 ? "s" : ""}) = {player.eliteEight * pointSystem[pointYear][Achievements.GOLD_TOP_8]}
        </Typography>
      ) : (
        ""
      )}
      {player.topSixteen && pointSystem[pointYear][Achievements.GOLD_TOP_16] !== undefined ? (
        <Typography variant="body2">
          ({player.topSixteen}) Top 16 ({pointSystem[pointYear][Achievements.GOLD_TOP_16]} point{pointSystem[pointYear][Achievements.GOLD_TOP_16] > 1 ? "s" : ""}) = {player.topSixteen * pointSystem[pointYear][Achievements.GOLD_TOP_16]}
        </Typography>
      ) : (
        ""
      )}
      {player.silverWinner && pointSystem[pointYear][Achievements.SILVER_WINNER] !== undefined ? (
        <Typography variant="body2">
          ({player.silverWinner}) Silver champion ({pointSystem[pointYear][Achievements.SILVER_WINNER]} point{pointSystem[pointYear][Achievements.SILVER_WINNER] > 1 ? "s" : ""}) = {player.silverWinner * pointSystem[pointYear][Achievements.SILVER_WINNER]}
        </Typography>
      ) : (
        ""
      )}
      {player.silverSecond && pointSystem[pointYear][Achievements.SILVER_RUNNER_UP] !== undefined ? (
        <Typography variant="body2">
          ({player.silverSecond}) Silver runner up ({pointSystem[pointYear][Achievements.SILVER_RUNNER_UP]} point{pointSystem[pointYear][Achievements.SILVER_RUNNER_UP] > 1 ? "s" : ""}) = {player.silverSecond * pointSystem[pointYear][Achievements.SILVER_RUNNER_UP]}
        </Typography>
      ) : (
        ""
      )}
      {player.silverSemis && pointSystem[pointYear][Achievements.SILVER_SEMIS] !== undefined ? (
        <Typography variant="body2">
          ({player.silverSemis}) Silver semifinals ({pointSystem[pointYear][Achievements.SILVER_SEMIS]} point{pointSystem[pointYear][Achievements.SILVER_SEMIS] > 1 ? "s" : ""}) = {player.silverSemis * pointSystem[pointYear][Achievements.SILVER_SEMIS]}
        </Typography>
      ) : (
        ""
      )}
    </>
  );
}

class Standings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playerMap: {},
      currentYear: 0,
      currentMonth: '',
      pointYear: 0,
      possibleYears: new Set(),
      possibleMonths: new Set(),
      isLoaded: false,
      sort: Sort.SORT_DEFAULT,
      sortOrder: SortOrder.SORT_ORDER_DESC,
      sortRoundName: '',
    };
  }

  static getPlayerMapInternal(currentYear, currentMonth){
    return new Promise((resolve, reject) => {
      Promise.all([
        apiFetch("qualifying-attempts"),
        apiFetch("events"),
        apiFetch("rounds"),
        apiFetch("matches"),
        apiFetch("games"),
        apiFetch("players"),
      ]).then(
        ([
          qualsResponse,
          eventsResponse,
          roundsResponse,
          matchesResponse,
          gamesResponse,
          playersResponse,
        ]) => {
          let currentEvent = undefined;
          if (currentMonth !== undefined) {
            for (let event of eventsResponse) {
              if (event.name.includes(currentYear) && event.name.includes(currentMonth)) {
                currentEvent = event;
                break;
              }
            }
          }
          let date = new Date();
          date.setFullYear( date.getFullYear() - 1 );
          let eventMap = new Map(
            eventsResponse
              .filter((event) => (event.name.includes(currentYear) || currentYear === '9999' || (currentYear === '-1' && Date.parse(event.start_date) > date)) && (currentEvent === undefined || event.start_date <= currentEvent.start_date))
              .map((event) => [event.id, event.name])
          );
          const pointYear = currentYear === '-1' || currentYear === '9999' ? Math.max(...Object.keys(pointSystem)) : Math.min(currentYear, Math.max(...Object.keys(pointSystem)));
          const qualMap = new Map(
            qualsResponse
              .filter((qual) => eventMap.has(qual.event_id))
              .map((qual) => [qual.id, qual])
          );
          const roundMap = new Map(
            roundsResponse
              .filter((round) => eventMap.has(round.event_id))
              .map((round) => [round.id, round])
          );
          const matchMap = new Map(
            matchesResponse
              .filter((match) => roundMap.has(match.round_id))
              .map((match) => [match.id, match])
          );
          const playerMap = new Map(
            playersResponse
              .filter((player) =>
                Array.from(qualMap.values())
                  .map((qual) => qual.player_id)
                  .concat(Array.from(matchMap.values()).map((match) => match.player_1_id))
                  .concat(Array.from(matchMap.values()).map((match) => match.player_2_id))
                  .includes(player.id)
              )
              .map((player) => [player.id, player])
          );
          for (let player of playerMap.values()) {
            player.events = Array.from(qualMap.values()).filter(
              (qual) => qual.attempt === 1 && qual.player_id === player.id
            ).length;
            player.eventIds = new Set();
            player.match_wins = player.match_losses = 0;
            player.total_seconds = player.total_viruses = 0;
            player.firstPlace = player.secondPlace = player.semis = 0;
            player.eliteEight = player.topSixteen = 0;
            player.silverWinner = player.silverSecond = player.silverSemis = 0;
            player.roundGames = {};
            player.roundTimes = {};
            player.roundBests = {};
            player.qualMap = new Map();
          }
          qualsResponse.filter((qual) => eventMap.has(qual.event_id)).forEach((qual) => {
            const key = qual.event_id;
            let player = playerMap.get(qual.player_id);
            if (!player.qualMap.has(key) || qual.attempt > player.qualMap.get(key).attempt) {
              player.qualMap.set(key, qual);
            }
          });
          const maxRounds = new Map();
          roundsResponse.filter((round) => eventMap.has(round.event_id)).forEach((round) => {
            if (!maxRounds.has(round.event_id)) {
              maxRounds.set(round.event_id, 0);
            }
            maxRounds.set(round.event_id, maxRounds.get(round.event_id)+1);
          });
          const gameList = Array.from(
            gamesResponse.filter((game) => matchMap.has(game.match_id))
          );
          const roundParticipation = new Map();
          for (let match of matchesResponse.filter((match) =>
            roundMap.has(match.round_id)
          )) {
            const round = roundMap.get(match.round_id);
            if (!roundParticipation.has(round.event_id)) {
              roundParticipation.set(round.event_id, new Map());
            }
            if (!roundParticipation.get(round.event_id).has(match.player_1_id) || roundParticipation.get(round.event_id).get(match.player_1_id) < round.order) {
              roundParticipation.get(round.event_id).set(match.player_1_id, round.order);
            }
            if (!roundParticipation.get(round.event_id).has(match.player_2_id) || roundParticipation.get(round.event_id).get(match.player_2_id) < round.order) {
              roundParticipation.get(round.event_id).set(match.player_2_id, round.order);
            }
          }
          const finalsParticipation = new Map();
          for (let match of matchesResponse.filter((match) =>
            roundMap.has(match.round_id)
          )) {
            if (match.player_1_id === null && match.player_2_id === null) continue;
            const roundNo = roundMap.get(match.round_id).order;
            const round = roundMap.get(match.round_id);
            const numRounds = maxRounds.get(round.event_id);
            const eventName = eventMap.get(round.event_id);
            const isSilver = eventName.match(/silver/i);
            let p1 = playerMap.get(match.player_1_id);
            let p2 = playerMap.get(match.player_2_id);
            if (!eventName.match(/championship/i)) {
              if (match.player_1_id !== null) p1.eventIds.add(round.event_id);
              if (match.player_2_id !== null) p2.eventIds.add(round.event_id);
            }
            if (eventName.match(/championship/i)) {
              if (p1 !== undefined) {
                if (!finalsParticipation.has(p1.id)) {
                  finalsParticipation.set(p1.id, new Set());
                }
                finalsParticipation.get(p1.id).add(round.event_id);
              }
              if (p2 !== undefined) {
                if (!finalsParticipation.has(p2.id)) {
                  finalsParticipation.set(p2.id, new Set());
                }
                finalsParticipation.get(p2.id).add(round.event_id);
              }
            }
            let p1Wins = 0;
            let p2Wins = 0;
            for (let game of gameList.filter(
              (game) => game.match_id === match.id
            )) {
              if (game.winning_player_number_forced === 1) {
                p1Wins++;
              } else if (game.winning_player_number_forced === 2) {
                p2Wins++;
              } else if (!game.player_2_time_seconds) {
                p1Wins++;
              } else if (!game.player_1_time_seconds) {
                p2Wins++;
              } else if (
                game.player_1_time_seconds < game.player_2_time_seconds
              ) {
                p1Wins++;
              } else {
                p2Wins++;
              }

              this.updateStats(game, round, p1, p2);
            }

            if (!eventName.match(/championship/i)) {
              const p1_participation = roundParticipation.get(round.event_id).get(match.player_1_id);
              const p2_participation = roundParticipation.get(round.event_id).get(match.player_2_id);
              if (roundNo === numRounds) {
                if (p1Wins > p2Wins) {
                  if (isSilver) {
                    p1.silverWinner++;
                    p2.silverSecond++;
                  } else {
                    p1.firstPlace++;
                    p2.secondPlace++;
                  }
                } else if (p1Wins < p2Wins) {
                  if (isSilver) {
                    p2.silverWinner++;
                    p1.silverSecond++;
                  } else {
                    p2.firstPlace++;
                    p1.secondPlace++;
                  }
                } else {
                  if (isSilver) {
                    if (match.player_1_id !== null) {
                      if (match.player_2_id === null) p1.silverWinner++;
                      else p1.silverSecond++;
                    }
                    if (match.player_2_id !== null) {
                      if (match.player_1_id === null) p2.silverWinner++;
                      else p2.silverSecond++;
                    }
                  } else {
                    if (match.player_1_id !== null) p1.secondPlace++;
                    if (match.player_2_id !== null) p2.secondPlace++;
                  }
                }
              } else if (roundNo === numRounds-1) {
                if (match.player_1_id !== null && p1_participation === roundNo) {
                  if (p1Wins > p2Wins && p1_participation === roundNo+1) {
                    if (isSilver) p1.silverSecond++;
                    else p1.secondPlace++;
                  } else if (p2Wins > p1Wins) {
                    if (isSilver) p1.silverSemis++;
                    else p1.semis++;
                  } else {
                    if (isSilver) p1.silverSemis++;
                    else p1.semis++;
                  }
                }
                if (match.player_2_id !== null && p2_participation === roundNo) {
                  if (p2Wins > p1Wins && p2_participation === roundNo+1) {
                    if (isSilver) p2.silverSecond++;
                    else p2.secondPlace++;
                  } else if (p1Wins > p2Wins) {
                    if (isSilver) p2.silverSemis++;
                    else p2.semis++;
                  } else {
                    if (isSilver) p2.silverSemis++;
                    else p2.semis++;
                  }
                }
              } else if (roundNo === numRounds-2) {
                if (match.player_1_id !== null && p1_participation === roundNo) {
                  if (p1Wins > p2Wins && p1_participation === roundNo+1) {
                    if (isSilver) p1.silverSemis++;
                    else p1.semis++;
                  } else if (p2Wins > p1Wins) {
                    if (!isSilver) p1.eliteEight++;
                  } else {
                    if (!isSilver) p1.eliteEight++;
                  }
                }
                if (match.player_2_id !== null && p2_participation === roundNo) {
                  if (p2Wins > p1Wins && p2_participation === roundNo+1) {
                    if (isSilver) p2.silverSemis++;
                    else p2.semis++;
                  } else if (p1Wins > p2Wins) {
                    if (!isSilver) p2.eliteEight++;
                  } else {
                    if (!isSilver) p2.eliteEight++;
                  }
                }
              } else if (roundNo === numRounds-3) {
                if (match.player_1_id !== null && p1_participation === roundNo) {
                  if (p1Wins > p2Wins && p1_participation === roundNo+1) {
                    if (!isSilver) p1.eliteEight++;
                  } else if (p2Wins > p1Wins) {
                    if (!isSilver) p1.topSixteen++;
                  } else {
                    if (!isSilver) p1.topSixteen++;
                  }
                }
                if (match.player_2_id !== null && p2_participation === roundNo) {
                  if (p2Wins > p1Wins && p2_participation === roundNo+1) {
                    if (!isSilver) p2.eliteEight++;
                  } else if (p1Wins > p2Wins) {
                    if (!isSilver) p2.topSixteen++;
                  } else {
                    if (!isSilver) p2.topSixteen++;
                  }
                }
              }
            }
            if (p1Wins > p2Wins) {
              p1.match_wins++;
              p2.match_losses++;
            } else if (p1Wins < p2Wins) {
              p2.match_wins++;
              p1.match_losses++;
            }
          }
          for (let player of playerMap.values()) {
            let points = 0;
            points += player.firstPlace * pointSystem[pointYear][Achievements.GOLD_WINNER];
            points += player.secondPlace * pointSystem[pointYear][Achievements.GOLD_RUNNER_UP];
            if (pointSystem[pointYear][Achievements.GOLD_SEMIS] !== undefined) {
              points += player.semis * pointSystem[pointYear][Achievements.GOLD_SEMIS];
            }
            if (pointSystem[pointYear][Achievements.GOLD_TOP_8] !== undefined) {
              points += player.eliteEight * pointSystem[pointYear][Achievements.GOLD_TOP_8];
            }
            if (pointSystem[pointYear][Achievements.GOLD_TOP_16] !== undefined) {
              points += player.topSixteen * pointSystem[pointYear][Achievements.GOLD_TOP_16];
            }
            if (pointSystem[pointYear][Achievements.SILVER_WINNER] !== undefined) {
              points += player.silverWinner * pointSystem[pointYear][Achievements.SILVER_WINNER];
            }
            if (pointSystem[pointYear][Achievements.SILVER_RUNNER_UP] !== undefined) {
              points += player.silverSecond * pointSystem[pointYear][Achievements.SILVER_RUNNER_UP];
            }
            if (pointSystem[pointYear][Achievements.SILVER_SEMIS] !== undefined) {
              points += player.silverSemis * pointSystem[pointYear][Achievements.SILVER_SEMIS];
            }
            player.clear_rate = player.total_seconds / player.total_viruses || 0;
            player.score = player.points = points;
            player.score -= player.clear_rate / 100;
            if (!player.total_seconds) {
              player.score -= 1;
            }
            if (currentYear >= 2023 || currentYear === '-1') {
                player.events = player.eventIds.size;
            }
            if ((currentYear === '9999' ||  currentYear === '-1') && finalsParticipation.has(player.id)) {
                player.events += finalsParticipation.get(player.id).size;
            }
          }
          let count = 0;
          let rank = 0;
          let prevScore = -10000;
          Array.from(playerMap.values())
            .sort(function (a, b) {
              return b.score - 1000 * (currentYear !== '9999' && (b.events < 2 || (currentYear > 2020 && b.events < 3))? 1 : 0) - (a.score - 1000 * (currentYear !== '9999' && (a.events < 2 || (currentYear > 2020 && a.events < 3)) ? 1 : 0))
            })
            .forEach((player) => {
              count++;
              if (player.score !== prevScore) {
                prevScore = player.score;
                rank = count;
              }
              player.rank = rank;
            });
          resolve(playerMap);
        }
      );
    });
  };

  static getPlayerStats(playerIdOrGamerTag){
    return new Promise((resolve, reject) => {
      Promise.all([
        apiFetch("qualifying-attempts"),
        apiFetch("events"),
        apiFetch("rounds"),
        apiFetch("matches"),
        apiFetch("games"),
        apiFetch(`players/${playerIdOrGamerTag}`)
      ]).then(
        ([
          qualsResponse,
          eventsResponse,
          roundsResponse,
          matchesResponse,
          gamesResponse,
          player,
        ]) => {
          const eventMap = new Map(
            eventsResponse
              .map((event) => [event.id, event])
          );
          const possibleYears = new Set(
            eventsResponse.map((event) =>
              event.start_date.replace(/([0-9]{4}).*/, "$1")
            )
          );
          player.stats = new Map();
          player.stats[0] = {};
          player.stats[-1] = {};   // last 12 months
          player.stats[-1].match_wins = player.stats[-1].match_losses = 0;
          player.stats[-1].total_seconds = player.stats[-1].total_viruses = 0;
          player.stats[-1].roundGames = {};
          player.stats[-1].roundTimes = {};
          player.stats[-1].roundBests = {};
          player.match_wins = player.match_losses = 0;
          player.total_seconds = player.total_viruses = 0;
          player.roundGames = {};
          player.roundTimes = {};
          player.roundBests = {};
          for (let year of possibleYears) {
            player.stats[year] = {};
            player.stats[year].match_wins = player.stats[year].match_losses = 0;
            player.stats[year].total_seconds = player.stats[year].total_viruses = 0;
            player.stats[year].roundGames = {};
            player.stats[year].roundTimes = {};
            player.stats[year].roundBests = {};
          }
          const roundMap = new Map(
            roundsResponse
              .filter((round) => eventMap.has(round.event_id))
              .map((round) => [round.id, round])
          );
          const matchMap = new Map(
            matchesResponse
              .filter((match) => roundMap.has(match.round_id))
              .map((match) => [match.id, match])
          );
          const gameList = Array.from(
            gamesResponse.filter((game) => matchMap.has(game.match_id))
          );
          for (let match of matchesResponse.filter((match) =>
            roundMap.has(match.round_id)
          )) {
            const round = roundMap.get(match.round_id);
            const event = eventMap.get(round.event_id);
            const year = event.start_date.substring(0,4);
            if (match.player_1_id === null || match.player_2_id === null) {
              continue;
            }
            let p1 = player.id === match.player_1_id ? player.stats[year] : {};
            let p2 = player.id === match.player_2_id ? player.stats[year] : {};
            for (let game of gameList.filter(
              (game) => game.match_id === match.id
            )) {
              this.updateStats(game, round, p1, p2);
            }
            let date = new Date();
            date.setFullYear( date.getFullYear() - 1 );
            if ((Date.parse(event.start_date)) > date) {
              p1 = player.id === match.player_1_id ? player.stats[-1] : {};
              p2 = player.id === match.player_2_id ? player.stats[-1] : {};
              for (let game of gameList.filter(
                (game) => game.match_id === match.id
              )) {
                this.updateStats(game, round, p1, p2);
              }
            }
          }
          const total_stats = player.stats[0];
          total_stats.match_wins = total_stats.match_losses = 0;
          total_stats.total_seconds = total_stats.total_viruses = 0;
          total_stats.roundGames = new Map();
          total_stats.roundTimes = new Map();
          total_stats.roundBests = new Map();
          for (const year of possibleYears) {
            const stats = player.stats[year];
            if (!("match_wins" in stats)) {
              continue;
            }
            total_stats.match_wins += stats.match_wins;
            total_stats.match_losses += stats.match_losses;
            total_stats.total_seconds += stats.total_seconds;
            total_stats.total_viruses += stats.total_viruses;
            Array.from(Object.getOwnPropertyNames(stats.roundGames)).forEach((roundName) => {
              if (!(roundName in total_stats.roundGames)) {
                total_stats.roundGames[roundName] = 0;
                total_stats.roundTimes[roundName] = 0;
                total_stats.roundBests[roundName] = 10000;
              }
              total_stats.roundGames[roundName] += stats.roundGames[roundName];
              total_stats.roundTimes[roundName] += stats.roundTimes[roundName];
              total_stats.roundBests[roundName] = Math.min(total_stats.roundBests[roundName], stats.roundBests[roundName]);
            });
          }
          resolve(player);
        }
      );
    });
  }
  
  static updateStats(game, round, p1, p2) {
    // We don't have times recorded for the early days. 20:00 for the winner and 30:00 for the loser was entered in the spreadsheet by convention. Do not use these for clear rate calculations.
    if (
      game.player_1_time_seconds === 1800 ||
      game.player_2_time_seconds === 1800 ||
      game.player_1_time_seconds === 1200 ||
      game.player_2_time_seconds === 1200
    ) {
      return;
    }

    const roundName = round.level_start + "-" + round.level_end;

    if ("match_wins" in p1 && (game.player_1_time_seconds || game.player_1_score)) {
      if (!(roundName in p1.roundGames)) {
        p1.roundGames[roundName] = 0;
        p1.roundTimes[roundName] = 0;
        p1.roundBests[roundName] = 10000;
      }
      p1.roundGames[roundName]++;

      if (game.player_1_time_seconds) {
        p1.total_seconds += game.player_1_time_seconds;
        p1.roundTimes[roundName] = p1.roundTimes[roundName] + game.player_1_time_seconds;
        p1.total_viruses += levelVirusCounts[roundName];
        p1.roundBests[roundName] = Math.min(p1.roundBests[roundName], game.player_1_time_seconds);
      } else {
        p1.total_seconds += game.player_2_time_seconds;
        const seconds = (game.player_2_time_seconds / game.player_1_score) *
          levelVirusCounts[roundName];
        p1.roundTimes[roundName] += seconds;
        p1.total_viruses += game.player_1_score;
      }
    }
    if ("match_wins" in p2 && (game.player_2_time_seconds || game.player_2_score)) {
      if (!(roundName in p2.roundGames)) {
        p2.roundGames[roundName] = 0;
        p2.roundTimes[roundName] = 0;
        p2.roundBests[roundName] = 10000;
      }
      p2.roundGames[roundName]++;

      if (game.player_2_time_seconds) {
        p2.total_seconds += game.player_2_time_seconds;
        p2.roundTimes[roundName] += game.player_2_time_seconds;
        p2.total_viruses += levelVirusCounts[roundName];
        p2.roundBests[roundName] = Math.min(p2.roundBests[roundName], game.player_2_time_seconds);
      } else {
        p2.total_seconds += game.player_1_time_seconds;
        const seconds = (game.player_1_time_seconds / game.player_2_score) *
          levelVirusCounts[roundName];
        p2.roundTimes[roundName] += seconds;
        p2.total_viruses += game.player_2_score;
      }
    }
  }

  static getPlayerMapCurrentYear(){
    return new Promise((resolve, reject) => {
      Promise.all([
        apiFetch("events"),
      ]).then(
        ([
          eventsResponse,
        ]) => {
          const possibleYears = new Set(
            eventsResponse.map((event) =>
              event.start_date.replace(/([0-9]{4}).*/, "$1")
            )
          );
          const currentYear = Math.max(...possibleYears);
          resolve(Standings.getPlayerMapInternal(currentYear));
        }
      );
    });
  }

  loadStandings(currentYear, currentMonth) {
    Promise.all([
      apiFetch("events"),
    ]).then(
      ([
        eventsResponse,
      ]) => {
        const possibleYears = new Set(
          eventsResponse.map((event) =>
            event.start_date.replace(/([0-9]{4}).*/, "$1")
          )
        );
        if (currentYear === undefined) {
          currentYear = Math.max(...possibleYears);
        }
        let pointYear = currentYear;
        let possibleMonths = new Set();
        if (currentYear === '9999' || currentYear === '-1') {
          pointYear = Math.max(...Object.keys(pointSystem));
        } else {
          pointYear = Math.min(currentYear, Math.max(...Object.keys(pointSystem)));
          possibleMonths = new Set(
            eventsResponse
            .filter((event) => event.name.includes(currentYear) && !event.name.match(/silver/i) && !event.name.match(/championship/i))
          );
          if (currentMonth === undefined) {
            let sortedMonths = Array.from(possibleMonths)
            .sort(function (a, b) {
              return b.start_date < a.start_date ? -1 : 1;
            });
            currentMonth = Array.from(sortedMonths)[0].name.split(' ')[0];
          }
        }
        Standings.getPlayerMapInternal(currentYear, currentMonth).then((playerMap) =>
          this.setState({
            playerMap,
            currentYear,
            pointYear,
            currentMonth,
            possibleYears,
            possibleMonths,
            isLoaded: true,
          })
        );
      }
    );
  }

  componentDidMount() {
    this.loadStandings(this.props.match.params.year, this.props.match.params.month);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.year !== prevProps.match.params.year || this.props.match.params.month !== prevProps.match.params.month) {
      this.loadStandings(this.props.match.params.year, this.props.match.params.month);
    }
  }

  sortButton(sort, label, sortRoundName) {
    return <><button className={styles.SortButton} onClick={() => this.sortBy(sort, sortRoundName)}>{label}</button>{this.sortIcon(sort, sortRoundName)}</>
  }

  sortIcon(sort, sortRoundName) {
    if (this.state.sort === sort && (!sortRoundName || this.state.sortRoundName === sortRoundName)) {
      return (this.state.sortOrder === SortOrder.SORT_ORDER_DESC && SortDescending.has(sort)) || (this.state.sortOrder === SortOrder.SORT_ORDER_ASC && !SortDescending.has(sort)) ?
        <ArrowDownwardIcon className={styles.SortIcon} /> :
        <ArrowUpwardIcon className={styles.SortIcon} />;
    }
    return <></>; 
  }

  sortPlayers() {
    const {playerMap, currentYear, sort, sortOrder, sortRoundName} = this.state;
    // prioritize players by number of events (3 required for 2021), followed by points, then clear rate
    let champSortKeyFn = (player) => player.score - 1000 * (currentYear !== '9999' && (player.events < 2 || (currentYear > 2020 && player.events < 3)) ? 1 : 0);
    let sortKeyFn = () => 0;
    let sortFn = () => 0;
    let champSortFn = (a, b) => {
      const avgA = champSortKeyFn(a);
      const avgB = champSortKeyFn(b);
      if (avgA === avgB) {
        return a.gamer_tag.toLowerCase() > b.gamer_tag.toLowerCase() ? 1 : -1;
      }
      return avgB - avgA;
    };
	
    switch (sort) {
      case Sort.SORT_DEFAULT:
        sortKeyFn = champSortKeyFn;
        sortFn = (a, b) => {
          const avgA = sortKeyFn(a);
          const avgB = sortKeyFn(b);
          if (avgA === avgB) {
            return a.gamer_tag.toLowerCase() > b.gamer_tag.toLowerCase() ? 1 : -1;
          }
          if (sortOrder === SortOrder.SORT_ORDER_DESC) {
            return avgB - avgA;
          } else {
            return avgA - avgB;
          }
        };
        break;
      case Sort.SORT_POINTS:
        sortKeyFn = (player) => player.score;
        sortFn = (a, b) => {
          if (a.score === b.score) {
            return a.gamer_tag.toLowerCase() > b.gamer_tag.toLowerCase() ? 1 : -1;
          }
          if (sortOrder === SortOrder.SORT_ORDER_DESC) {
            return b.score - a.score;
          } else {
            return a.score - b.score;
          }
        };
        break;
      case Sort.SORT_CLEAR_RATE:
        sortKeyFn = (player) => player.clear_rate;
        sortFn = (a, b) => {
          if (a.clear_rate === b.clear_rate) {
            return a.gamer_tag.toLowerCase() > b.gamer_tag.toLowerCase() ? 1 : -1;
          }
          if (a.clear_rate === 0) {
            return sortOrder === SortOrder.SORT_ORDER_DESC ? 1 : -1;
          } else if (b.clear_rate === 0) {
            return sortOrder === SortOrder.SORT_ORDER_DESC ? -1 : 1;
          }
          if (sortOrder === SortOrder.SORT_ORDER_DESC) {
            return a.clear_rate - b.clear_rate;
          } else {
            return b.clear_rate - a.clear_rate;
          }
        };
        break;
      case Sort.SORT_ROUND:
        sortKeyFn = (player) => player.roundTimes && player.roundGames[sortRoundName] ?
          player.roundTimes[sortRoundName] / player.roundGames[sortRoundName] :
          0;
        sortFn = (a, b) => {
          const avgA = sortKeyFn(a);
          const avgB = sortKeyFn(b);
          if (avgA === avgB) {
            return a.gamer_tag.toLowerCase() > b.gamer_tag.toLowerCase() ? 1 : -1;
          }
          if (avgA === 0) {
            return 1;
          } else if (avgB === 0) {
            return -1;
          }
          if (sortOrder === SortOrder.SORT_ORDER_DESC) {
            return avgA - avgB;
          } else {
            return avgB - avgA;
          }
        };
        break;
      case Sort.SORT_PLAYER:
        sortKeyFn = (player) => player.gamer_tag.toLowerCase();
        sortFn = (a, b) => {
          if (sortOrder === SortOrder.SORT_ORDER_DESC) {
            return a.gamer_tag.toLowerCase() > b.gamer_tag.toLowerCase() ? 1 : -1;
          }
          return a.gamer_tag.toLowerCase() > b.gamer_tag.toLowerCase() ? -1 : 1;
        };
        break;
      case Sort.SORT_EVENTS:
        sortKeyFn = (player) => player.events;
        sortFn = (a, b) => {
          if (sortOrder === SortOrder.SORT_ORDER_DESC) {
            return a.events > b.events ? -1 : 1;
          }
          return a.events > b.events ? 1 : -1;
        };
        break;
      case Sort.SORT_WINS:
        sortKeyFn = (player) => player.match_wins;
        sortFn = (a, b) => {
          if (a.match_wins === b.match_wins) {
            if (sortOrder === SortOrder.SORT_ORDER_DESC) {
              return a.match_losses > b.match_losses || a.match_wins+a.match_losses === 0 ? 1 : -1;
            }
            return a.match_losses > b.match_losses || a.match_wins+a.match_losses === 0 ? -1 : 1;
          }
          if (sortOrder === SortOrder.SORT_ORDER_DESC) {
            return a.match_wins > b.match_wins ? -1 : 1;
          }
          return a.match_wins > b.match_wins ? 1 : -1;
        };
        break;
      case Sort.SORT_WIN_PCT:
        sortKeyFn = (player) => player.match_wins || player.match_losses ? ((100.0 * (player.match_wins || 0)) / ((player.match_wins || 0) + (player.match_losses || 0))) : 0;
        sortFn = (a, b) => {
          const scoreA = sortKeyFn(a);
          const scoreB = sortKeyFn(b);
          if (scoreA === scoreB) {
            if (a.match_wins === b.match_wins) {
              return a.gamer_tag.toLowerCase() > b.gamer_tag.toLowerCase() ? 1 : -1;
            }
            if (sortOrder === SortOrder.SORT_ORDER_DESC) {
              return a.match_wins > b.match_wins ? -1 : 1;
            }
            return a.match_wins > b.match_wins ? 1 : -1;
            }
          if (sortOrder === SortOrder.SORT_ORDER_DESC) {
            return scoreA > scoreB ? -1 : 1;
          }
          return scoreA > scoreB ? 1 : -1;
        };
        break;
      case Sort.SORT_QUAL_AVE:
        sortKeyFn = (player) => Array.from(player.qualMap.values()).map(qual => qual.score).reduce((acc, val) => acc + val, 0)/player.qualMap.size;
        sortFn = (a, b) => {
          const avgA = sortKeyFn(a);
          const avgB = sortKeyFn(b);
          if (sortOrder === SortOrder.SORT_ORDER_DESC) {
            return avgB - avgA;
          } else {
            return avgA - avgB;
          }
        };
        break;
      case Sort.SORT_POINT_RATE:
        sortKeyFn = (player) => player.eventIds.size > 0 ? player.points / player.eventIds.size : 0;
        sortFn = (a, b) => {
          const avgA = sortKeyFn(a);
          const avgB = sortKeyFn(b);
          if (sortOrder === SortOrder.SORT_ORDER_DESC) {
            return avgB - avgA;
          } else {
            return avgA - avgB;
          }
        };
        break;
      default:
    }

    let prevValue = -10000;
    let rank = 0;
    let players = Array.from(playerMap.values());
    players = players
      .filter((player) => currentYear !== '9999' || player.match_wins + player.match_losses > 0)
      .sort(champSortFn)
      .map((player, index) => {
        if (champSortKeyFn(player) !== prevValue) {
          prevValue = champSortKeyFn(player);
          rank = index + 1;
        }
        player.champRank = rank;
        return player;
      });
    prevValue = -10000;
    rank = 0;
    return players
      .sort(sortFn)
      .map((player, index) => {
        if (sortKeyFn(player) !== prevValue) {
          prevValue = sortKeyFn(player);
          rank = index + 1;
        }
        player.rank = rank;
        return player;
      });
  }

  sortBy(sort, sortRoundName) {
    let sortOrder = SortOrder.SORT_ORDER_DESC;
    if (this.state.sort === sort && (!sortRoundName || this.state.sortRoundName === sortRoundName)) {
      if (this.state.sortOrder === SortOrder.SORT_ORDER_DESC) {
        sortOrder = SortOrder.SORT_ORDER_ASC;
      }
      if (this.state.sortOrder === SortOrder.SORT_ORDER_ASC) {
        sort = Sort.SORT_DEFAULT;
        sortOrder = SortOrder.SORT_ORDER_DESC;
      }
    }
    this.setState({
      sort,
      sortOrder,
      sortRoundName,
    });
  }

  render() {
    const { playerMap, currentYear, currentMonth, pointYear, possibleYears, possibleMonths, isLoaded } = this.state;
    if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      const possibleLevels = new Set();
      Array.from(playerMap.values())
        .forEach((player) => {
          if ("roundGames" in player) {
            Array.from(Object.getOwnPropertyNames(player.roundGames)).forEach((roundName) => possibleLevels.add(roundName));
          }
        });
      
      let count = 1;
      return (
        <div className={styles.Standings}>
          <Select
            name="year"
            value={currentYear}
            onChange={(event) => {
              this.props.history.push(`/standings/${event.target.value}`);
            }}
          >
            {[9999, -1].concat(Array.from(possibleYears))
              .sort(function (a, b) {
                return b === -1 ? 1 : b - a;
              })
              .map((year) => {
                return <MenuItem value={year}>{year === 9999 ? "All" : (year === -1 ? "Last 12 months" : year)}</MenuItem>;
              })}
          </Select>
          &nbsp;
          <span className={possibleMonths.size > 0 ? "" : styles.Hidden}>
            <Select
              name="month"
              value={currentMonth}
              onChange={(event) => {
                this.props.history.push(`/standings/${currentYear}/${event.target.value}`);
              }}
            >
              {Array.from(possibleMonths)
                .sort(function (a, b) {
                  return b.start_date < a.start_date ? 1 : -1;
                })
                .map((month) => {
                  return <MenuItem value={month.name.split(' ')[0]}>{month.name.split(' ')[0]}</MenuItem>;
                })}
            </Select>
          </span>
          <Typography variant="h3">{currentYear === '9999' ? "All Time" : (currentYear === '-1' ? "Last 12 months" : currentYear)} Standings</Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan="6"></TableCell>
                  <TableCell
                    align="center"
                    colSpan="2"
                    className={styles.Matches}
                  >
                    Matches
                  </TableCell>
                  {possibleLevels.size > 0 ?
                  (<TableCell
                    align="center"
                    colSpan={possibleLevels.size}
                    className={styles.AverageTime}
                  >
                    Average Time
                  </TableCell>) : ""}
                  <TableCell className={styles.Matches}></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left">
                    {this.sortButton(Sort.SORT_PLAYER, 'Player')}
                  </TableCell>
                  <TableCell align="right">
                    {this.sortButton(Sort.SORT_EVENTS, 'Events')}
                  </TableCell>
                  <TableCell align="right">
                    {this.sortButton(Sort.SORT_POINTS, 'Points')}
                  </TableCell>
                  <TableCell align="right">
                    {this.sortButton(Sort.SORT_POINT_RATE, 'Pts/Event')}
                  </TableCell>
                  <TableCell align="right">
                    {this.sortButton(Sort.SORT_CLEAR_RATE, 'Clear Rate')}
                  </TableCell>
                  <TableCell align="right" className={styles.Matches}>
                    {this.sortButton(Sort.SORT_WINS, 'W-L')}
                  </TableCell>
                  <TableCell align="right" className={styles.Matches}>
                    {this.sortButton(Sort.SORT_WIN_PCT, 'Win%')}
                  </TableCell>
                  {Array.from(possibleLevels)
                    .sort(function (a, b) {
                      return parseInt(a.split("-")[0])*parseInt(a.split("-")[1]) - parseInt(b.split("-")[0])*parseInt(b.split("-")[1]);
                    })
                    .map((roundName) => {
                      return <TableCell align="right" className={styles.AverageTime}>
                          {this.sortButton(Sort.SORT_ROUND, roundName, roundName)}
                        </TableCell>;
                  })}
                  <TableCell align="right" className={styles.Matches}>
                    {this.sortButton(Sort.SORT_QUAL_AVE, 'Qual Ave.')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.sortPlayers()
                  .map((player) => {
                    const playerImage = player.twitch_user ? (
                      <Image
                        src={player.twitch_user.profile_image_url}
                        className={styles.PlayerImage}
                      />
                    ) : (
                      ""
                    );
                    return (
                      <TableRow
                        key={player.id}
                        className={
                          currentYear !== '9999' && currentYear !== '-1' && player.champRank <= 16 &&
                          (player.events >= 3 ||
                            (currentYear <= 2020 && player.events >= 2))
                            ? styles.ChampionshipEligible
                            :
                          currentYear !== '9999' && currentYear !== '-1' && player.champRank > 16 && player.champRank <=32 &&
                          player.events >= 3 && currentYear >= 2021
                            ? styles.SilverEligible
                            : ""
                        }
                      >
                        <TableCell align="right">{player.rank === count++ ? player.rank : ""}</TableCell>
                        <TableCell>
                          <div className={styles.PlayerInfo}>
                            {playerImage}
                            <Link to={"/players/" + player.gamer_tag + "?year=" + (currentYear === '9999' ? "0" : currentYear)}>
                              {player.gamer_tag}
                            </Link>
                          </div>
                        </TableCell>
                        <TableCell align="right">{player.events}</TableCell>
                        <TableCell align="right">
                          <Tooltip
                            key={"tt" + player.id}
                            title={getPointsDescription(player, pointYear)}
                          >
                            <div>{player.points}</div>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="right">
                          {(player.eventIds.size > 0 ? (player.points/player.eventIds.size) : 0).toFixed(3)}
                        </TableCell>
                        <TableCell align="right">
                          {player.clear_rate.toFixed(3)}
                        </TableCell>
                        <TableCell align="right" className={styles.Matches}>
                          {player.match_wins || 0}-{player.match_losses || 0}
                        </TableCell>
                        <TableCell align="right" className={styles.Matches}>
                          {player.match_wins || player.match_losses
                            ? (
                                (100.0 * (player.match_wins || 0)) /
                                ((player.match_wins || 0) +
                                  (player.match_losses || 0))
                              ).toFixed(2)
                            : "0.00"}
                          %
                        </TableCell>
                        {Array.from(possibleLevels)
                          .sort(function (a, b) {
                            return parseInt(a.split("-")[0])*parseInt(a.split("-")[1]) - parseInt(b.split("-")[0])*parseInt(b.split("-")[1]);
                          })
                          .map((roundName) => {
                            return <TableCell align="right" className={styles.AverageTime}>
                              {player.roundTimes && player.roundGames[roundName]
                                ? secondsToTime(
                                    player.roundTimes[roundName] / player.roundGames[roundName]
                                  )
                                : ""}
                            </TableCell>;
                          })}
                        <TableCell align="right" className={styles.Matches}	>
                          <Tooltip
                            key={"qa" + player.id}
                            title={(Array.from(player.qualMap.values()).map(qual => qual.score).reduce((acc, val) => acc + val, 0)/player.qualMap.size).toFixed(3)}
                          >
                            <div>{scoreToVirusScore(parseInt(Array.from(player.qualMap.values()).map(qual => qual.score).reduce((acc, val) => acc + val, 0)/player.qualMap.size), true)}</div>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    }
  }
}

export default Standings;
