import styles from './PlayerImageGraphql.module.scss';

interface Player {
  gamerTag: string;
  twitchUser?: TwitchUser;
}

interface TwitchUser {
  profileImageUrl: string;
}

interface Props {
  player: Player;
}

export function PlayerImage(props: Props) {
  const profileImageUrl = props.player.twitchUser?.profileImageUrl;
  return profileImageUrl === undefined ? (
    <></>
  ) : (
    <img
      src={profileImageUrl}
      className={styles.Image}
      alt={`Profile image of user ${props.player.gamerTag}`}
    />
  );
}
