import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Image from "./Image";
import {apiFetch} from "./utils/api_fetch";
import { secondsToTime } from "./utils/time";

import styles from "./HotTimes.module.scss";

class Record {
  constructor(event_id, player_id, seconds) {
    this.event_id = event_id;
    this.player_id = player_id;
    this.seconds = seconds;
  }
}

class HotTimes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      playerMap: {},
      possiblePlayers: new Set(),
      currentPlayer: null,
      eventMap: {},
      currentYear: 0,
      possibleYears: new Set(),
      currentRound: "",
      possibleRounds: new Map(),
      isLoaded: false,
      numberToShow: 20,
    };
  }

  loadHotTimes(currentYear, currentRound, currentPlayer, isInitialLoad = false) {
    Promise.all([
      apiFetch("events"),
      apiFetch("rounds"),
      apiFetch("matches"),
      apiFetch("games"),
      apiFetch("players"),
    ]).then(
      ([
        eventsResponse,
        roundsResponse,
        matchesResponse,
        gamesResponse,
        playersResponse,
      ]) => {
        let possibleYears = new Set(
          eventsResponse.map((event) =>
            event.start_date.replace(/([0-9]{4}).*/, "$1")
          )
        );
        if (currentYear === null) {
          currentYear = "0";
        }
        if (currentPlayer === null) {
          currentPlayer = "All";
        }
        const eventMap = new Map(
          eventsResponse
            .filter(
              (event) => currentYear === "0" || event.name.includes(currentYear)
            )
            .map((event) => [event.id, event.name])
        );
        let filteredRounds = roundsResponse.filter((round) => eventMap.has(round.event_id));
        const roundMap = new Map(
          filteredRounds
            .filter(
              (round) =>
                round.level_start + "-" + round.level_end === currentRound || currentRound === null
            )
            .map((round) => [round.id, round])
        );
        const playerMap = new Map(
          playersResponse.map((player) => [player.id, player])
        );
        const matchMap = new Map(
          matchesResponse
            .filter((match) => roundMap.has(match.round_id))
            .map((match) => [match.id, match])
        );
        const matches = Array.from(matchMap.values()).filter((match) => match.player_1_id !== null && match.player_2_id !== null);
        const possiblePlayers = new Set([...matches.map((match) => playerMap.get(match.player_1_id).gamer_tag), ...matches.map((match) => playerMap.get(match.player_2_id).gamer_tag)]);
        if (currentPlayer !== "All") {
            if (!possiblePlayers.has(currentPlayer)) {
                currentPlayer = "All";
            } else {
              const rounds = new Set(matchesResponse.filter((match) => match.player_1_id !== null && match.player_2_id !== null && (playerMap.get(match.player_1_id).gamer_tag === currentPlayer || playerMap.get(match.player_2_id).gamer_tag === currentPlayer)).map((match) => match.round_id));
              filteredRounds = Array.from(filteredRounds).filter((round) => rounds.has(round.id));
              const allEvents = new Map(eventsResponse.map((event) => [event.id, event.start_date.replace(/([0-9]{4}).*/, "$1")]));
              const allRounds = new Map(roundsResponse.map((round) => [round.id, round]));
              possibleYears = new Set(Array.from(rounds).map((round) => allEvents.get(allRounds.get(round).event_id)));
            }
        }
        let possibleRounds = new Set(
          filteredRounds
            .map((round) => round.level_start + "-" + round.level_end)
        );
        if (!currentRound || !possibleRounds.has(currentRound)) {
          isInitialLoad = true;
          if (possibleRounds.size > 0) {
            currentRound = Array.from(possibleRounds).sort((a, b) => parseInt(a.split('-')[0]) - parseInt(b.split('-')[0]))[0];
          } else {
            currentRound = "";
          }
        }
        if (isInitialLoad) {
          this.props.history.replace(
            `/hottimes?year=${currentYear}&round=${currentRound}&player=${currentPlayer}`
          );
        }
        let records = [];
        for (let game of gamesResponse.filter((game) =>
          matchMap.has(game.match_id)
        )) {
          const match = matchMap.get(game.match_id);
          const round = roundMap.get(match.round_id);

          if (game.player_1_time_seconds === 1200 || game.player_1_time_seconds === 1800) {
            continue;
          }

          if (game.player_1_time_seconds && (currentPlayer === "All" || playerMap.get(match.player_1_id).gamer_tag === currentPlayer)) {
            records.push(
              new Record(
                round.event_id,
                match.player_1_id,
                game.player_1_time_seconds
              )
            );
          }
          if (game.player_2_time_seconds && (currentPlayer === "All" || playerMap.get(match.player_2_id).gamer_tag === currentPlayer)) {
            records.push(
              new Record(
                round.event_id,
                match.player_2_id,
                game.player_2_time_seconds
              )
            );
          }
        }

        this.setState({
          records: records.sort((a, b) => a.seconds - b.seconds),
          playerMap,
          possiblePlayers,
          currentPlayer,
          eventMap,
          currentYear,
          possibleYears,
          currentRound,
          possibleRounds,
          isLoaded: true,
          numberToShow: 20
        });
      }
    );
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    this.loadHotTimes(params.get("year"), params.get("round"), params.get("player"), true /* isInitialLoad */);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      const params = new URLSearchParams(this.props.location.search);
      this.loadHotTimes(params.get("year"), params.get("round"), params.get("player"));
    }
    if (this.state.numberToShow !== 20) {
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
  }

  render() {
    const {
      records,
      playerMap,
      possiblePlayers,
      currentPlayer,
      eventMap,
      currentYear,
      possibleYears,
      currentRound,
      possibleRounds,
      isLoaded,
      numberToShow,
    } = this.state;
    if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      let count = 0;
      let rank = 0;
      let prevSeconds = -1;
      return (
        <div className={styles.RecordsContainer}>
          Year:{" "}
          <Select
            name="year"
            value={currentYear}
            onChange={(event) => {
              this.props.history.push(
                `/hottimes?year=${event.target.value}&round=${currentRound}&player=${currentPlayer}`
              );
            }}
          >
            <MenuItem value={0}>All</MenuItem>
            {Array.from(possibleYears)
              .sort(function (a, b) {
                return b - a;
              })
              .map((year) => {
                return <MenuItem value={year}>{year}</MenuItem>;
              })}
          </Select>
          Levels:{" "}
          <Select
            name="round"
            value={currentRound}
            onChange={(event) => {
              this.props.history.push(
                `/hottimes?year=${currentYear}&round=${event.target.value}&player=${currentPlayer}`
              );
            }}
          >
            {Array.from(possibleRounds)
              .sort(function (a, b) {
                return parseInt(a.split("-")[0])*parseInt(a.split("-")[1]) - parseInt(b.split("-")[0])*parseInt(b.split("-")[1]);
              })
              .map((round) => {
                return (
                  <MenuItem value={round}>{round}</MenuItem>
                );
              })}
          </Select>
          Player:{" "}
          <Select
            name="player"
            value={currentPlayer}
            onChange={(event) => {
              this.props.history.push(
                `/hottimes?year=${currentYear}&round=${currentRound}&player=${event.target.value}`
              );
            }}
          >
            {["All"].concat(Array.from(possiblePlayers.values())
              .sort(function (a, b) {
                return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
              }))
              .map((player) => {
                return <MenuItem value={player}>{player}</MenuItem>;
              })}
          </Select>
          <Typography variant={currentPlayer === "All" ? "h3" : "h4"}>
            {currentYear === "0" ? "" : currentYear}{" "}
            {currentRound} Hot Times {currentPlayer === "All" ? "" : "for " + currentPlayer}
          </Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="right">Rank</TableCell>
                  <TableCell align="right">Time</TableCell>
                  <TableCell>Player</TableCell>
                  <TableCell>Event</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {records.slice(0, numberToShow).map((record) => {
                  count++;
                  if (record.seconds !== prevSeconds) {
                    prevSeconds = record.seconds;
                    rank = count;
                  }
                  const player = playerMap.get(record.player_id);
                  const playerImage = player.twitch_user ? (
                    <Image
                      src={player.twitch_user.profile_image_url}
                      className={styles.PlayerImage}
                    />
                  ) : (
                    ""
                  );
                  const eventName = eventMap.get(record.event_id);
                  return (
                    <TableRow key={count}>
                      <TableCell align="right">{rank === count ? rank : ""}</TableCell>
                      <TableCell align="right">
                        {secondsToTime(record.seconds)}
                      </TableCell>
                      <TableCell>
                        <div className={styles.PlayerInfo}>
                          {playerImage}
                          <Link to={"/players/" + record.player_id}>
                            {player.gamer_tag}
                          </Link>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={styles.EventInfo}>
                          <Link to={"/events/" + record.event_id}>
                            {eventName}
                          </Link>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          { numberToShow < records.length ?
          (<>
          <br></br>
          <center>
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                this.setState({
                  records,
                  playerMap,
                  eventMap,
                  currentYear,
                  possibleYears,
                  currentRound,
                  possibleRounds,
                  isLoaded,
                  numberToShow: numberToShow+20
                })
              }
            >
              Show More
            </Button>
          </center></>) : ""}
          <div style={{ float:"left", clear: "both" }}
             ref={(el) => { this.messagesEnd = el; }}>
          </div>
        </div>
      );
    }
  }
}

export default HotTimes;
