import React from "react";
import { Link } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Tooltip from "@mui/material/Tooltip";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip as ChartTooltip,
  Legend,
  Colors,
} from 'chart.js';
import { Line } from "react-chartjs-2";

import Image from "./Image";
import {apiFetch} from "./utils/api_fetch";
import { secondsToTime } from "./utils/time";
import { scoreToVirusScore, splitVirusScore, virusScoreToScore } from "./utils/virus_score";

import styles from "./Stats.module.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartTooltip,
  Legend,
  Colors,
);

let levelVirusCounts = { "6-9": 136, "8-11": 168, "10-14": 260, "13-17": 320, "4-7": 104, "10-13": 200, "14-17": 264};

const Sort = {
  SORT_DEFAULT: 0,
  SORT_ROUND: 1,
  SORT_QUAL_AVERAGE: 2,
  SORT_QUAL_STATS: 3,
}; 

const SortOrder = {
  SORT_ORDER_ASC: 0,
  SORT_ORDER_DESC: 1,
};

class Stats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventMap: {},
      currentYear: 0,
      possibleYears: new Set(),
      silverMode: false,
      isLoaded: false,
      sort: Sort.SORT_DEFAULT,
      sortOrder: SortOrder.SORT_ORDER_DESC,
      sortRoundName: '',
      sortLabel: '',
    };
  }

  static getPlayerMapInternal(currentYear, silverMode){
    return new Promise((resolve, reject) => {
      Promise.all([
        apiFetch("qualifying-attempts"),
        apiFetch("events"),
        apiFetch("rounds"),
        apiFetch("matches"),
        apiFetch("games"),
        apiFetch("players"),
      ]).then(
        ([
          qualsResponse,
          eventsResponse,
          roundsResponse,
          matchesResponse,
          gamesResponse,
          playersResponse,
        ]) => {
          const eventMap = new Map(
            eventsResponse
              .filter((event) => currentYear === 0 || event.name.includes(currentYear))
              .map((event) => [event.id, event])
          );
          const qualMap = new Map();
          qualsResponse
            .filter((qual) => eventMap.has(qual.event_id))
            .forEach((qual) => {
              if (!qualMap.has(qual.event_id)) {
                qualMap.set(qual.event_id, new Map());
              }
              let innerMap = qualMap.get(qual.event_id);
              if (!innerMap.has(qual.player_id) || qual.attempt > innerMap.get(qual.player_id).attempt) {
                innerMap.set(qual.player_id, qual);
              }
            });
          for (let event of eventMap.values()) {
            event.roundGames = {};
            event.roundTimes = {};
            event.winningRoundGames = {};
            event.winningRoundTimes = {};
            event.finalsCount1 = 0;
            event.finalsCount2 = 0;
            event.lev16 = 0;
            event.lev17 = 0;
            event.lev18 = 0;
            event.lev19 = 0;
          }
          for (let [key, value] of qualMap) {
            let event = eventMap.get(key);
            event.qualifiers = Array.from(value.values()).filter(q => !q.is_drop).map(q => q.score).sort((a, b) => b-a).slice(0, 16);
            event.silver_quals = Array.from(value.values()).filter(q => !q.is_drop).map(q => q.score).sort((a, b) => b-a).slice(16);
          }
          for (let event of eventMap.values()) {
            for (const e of eventMap.values()) {
              if (silverMode && event.name.match(/silver/i) && e.slug + '-silver' === event.slug) {
                if ('silver_quals' in e) {
                  event.qualifiers = e.silver_quals;
                }
                break;
              }
            }
            if (!('qualifiers' in event)) continue;
            for (let qual of event.qualifiers) {
              if (qual >= 544) event.lev16++;
              if (qual >= 612) event.lev17++;
              if (qual >= 684) event.lev18++;
              if (qual >= 760) event.lev19++;
            }
		  }
          for (let [id, event] of eventMap) {
            if (silverMode && !event.name.match(/silver/i)) {
              eventMap.delete(id);
            }
            if (!silverMode && event.name.match(/silver/i)) {
              eventMap.delete(id);
            }
          }
          const playerMap = new Map(
            playersResponse
              .map((player) => [player.id, player])
          );
          const maxRounds = new Map();
          roundsResponse.filter((round) => eventMap.has(round.event_id)).forEach((round) => {
            if (!maxRounds.has(round.event_id)) {
              maxRounds.set(round.event_id, 0);
            }
            maxRounds.set(round.event_id, maxRounds.get(round.event_id)+1);
          });
          const roundMap = new Map(
            roundsResponse
              .filter((round) => eventMap.has(round.event_id))
              .map((round) => [round.id, round])
          );
          const matchMap = new Map(
            matchesResponse
              .filter((match) => roundMap.has(match.round_id))
              .map((match) => [match.id, match])
          );
          const gameList = Array.from(
            gamesResponse.filter((game) => matchMap.has(game.match_id))
          );

          for (let match of matchesResponse.filter((match) =>
            roundMap.has(match.round_id)
          )) {
            const round = roundMap.get(match.round_id);
            const event = eventMap.get(round.event_id);
            let p1 = playerMap.get(match.player_1_id);
            let p2 = playerMap.get(match.player_2_id);

            if (silverMode && maxRounds.get(round.event_id) === round.order && round.order === 1 && match.player_2_id === null) {
              event.finalsCount1 = 2;
              event.finalist1 = p1;
            }

            for (let game of gameList.filter(
              (game) => game.match_id === match.id
            )) {
              this.updateStats(game, round, p1, p2, event, maxRounds.get(round.event_id));
            }
          }
          resolve(eventMap);
        }
      );
    });
  };
  
  static updateStats(game, round, p1, p2, event, numRounds) {
    if (round.order === numRounds) {
      event.finalist1 = p1;
      event.finalist2 = p2;
      if ((game.player_1_time_seconds && (!game.player_2_time_seconds || game.player_1_time_seconds < game.player_2_time_seconds)) || game.winning_player_number_forced === 1) {
        event.finalsCount1 += 1;
	  }
      else if ((game.player_2_time_seconds && (!game.player_1_time_seconds || game.player_2_time_seconds < game.player_1_time_seconds)) || game.winning_player_number_forced === 2) {
        event.finalsCount2 += 1;
      }
    }

    // We don't have times recorded for the early days. 20:00 for the winner and 30:00 for the loser was entered in the spreadsheet by convention. Do not use these for clear rate calculations.
    if (
      game.player_1_time_seconds === 1800 ||
      game.player_2_time_seconds === 1800 ||
      game.player_1_time_seconds === 1200 ||
      game.player_2_time_seconds === 1200 ||
      game.winning_player_number_forced !== 0) return;

    const roundName = round.level_start + "-" + round.level_end;

    if (!(roundName in event.roundGames)) {
      event.roundGames[roundName] = 0;
      event.roundTimes[roundName] = 0;
      event.winningRoundGames[roundName] = 0;
      event.winningRoundTimes[roundName] = 0;
    }

    event.roundGames[roundName]++;
    if (game.player_1_time_seconds) {
      event.roundTimes[roundName] += game.player_1_time_seconds;
	  if (!game.player_2_time_seconds || game.player_1_time_seconds < game.player_2_time_seconds) {
        event.winningRoundGames[roundName]++;
        event.winningRoundTimes[roundName] += game.player_1_time_seconds;
      }
    } else {
      const seconds = (game.player_2_time_seconds / game.player_1_score) *
        levelVirusCounts[roundName];
        event.roundTimes[roundName] += seconds;
    }

    event.roundGames[roundName]++;
    if (game.player_2_time_seconds) {
      event.roundTimes[roundName] += game.player_2_time_seconds;
      if (!game.player_1_time_seconds || game.player_2_time_seconds < game.player_1_time_seconds) {
        event.winningRoundGames[roundName]++;
        event.winningRoundTimes[roundName] += game.player_2_time_seconds;
      }
    } else {
      const seconds = (game.player_1_time_seconds / game.player_2_score) *
        levelVirusCounts[roundName];
      event.roundTimes[roundName] += seconds;
    }
  }

  loadStandings(currentYear, bracket) {
    Promise.all([
      apiFetch("events"),
    ]).then(
      ([
        eventsResponse,
      ]) => {
        const silverMode = bracket === 'silver';
        const possibleYears = new Set(
          eventsResponse
            .filter((event) => !silverMode || event.name.match(/silver/i))
            .map((event) =>
            event.start_date.replace(/([0-9]{4}).*/, "$1")
          )
        );
        if (currentYear === undefined) {
          currentYear = Math.max(...possibleYears);
        }
        currentYear = parseInt(currentYear);

        Stats.getPlayerMapInternal(currentYear, silverMode).then((eventMap) =>
          this.setState({
            eventMap,
            currentYear,
            possibleYears,
            silverMode,
            isLoaded: true,
          })
        );
      }
    );
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    this.loadStandings(this.props.match.params.year, params.get("bracket"));
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search || this.props.match.params.year !== prevProps.match.params.year) {
      const params = new URLSearchParams(this.props.location.search);
      this.loadStandings(this.props.match.params.year, params.get("bracket"));
    }
  }

  sortButton(sort, label, sortRoundName) {
    return <><button className={styles.SortButton} onClick={() => this.sortBy(sort, sortRoundName, label)}>{label}</button>{this.sortIcon(sort, sortRoundName, label)}</>
  }

  sortIcon(sort, sortRoundName, sortLabel) {
    if (this.state.sort === sort && (!sortRoundName || this.state.sortRoundName === sortRoundName) && this.state.sortLabel === sortLabel) {
      return (this.state.sortOrder === SortOrder.SORT_ORDER_DESC && (sort === Sort.SORT_QUAL_AVERAGE || sort === Sort.SORT_QUAL_STATS)) ||
	         (this.state.sortOrder === SortOrder.SORT_ORDER_ASC && !(sort === Sort.SORT_QUAL_AVERAGE || sort === Sort.SORT_QUAL_STATS)) ?
        <ArrowDownwardIcon className={styles.SortIcon} /> :
        <ArrowUpwardIcon className={styles.SortIcon} />;
    }
    return <></>;
  }

  sortEvents() {
    const {eventMap, sort, sortOrder, sortRoundName, sortLabel} = this.state;
    let sortKeyFn = () => 0;
    let sortFn = () => 0;
    switch (sort) {
      case Sort.SORT_DEFAULT:
        sortKeyFn = (event) => event.start_date;
        sortFn = (a, b) => {
          if (sortOrder === SortOrder.SORT_ORDER_DESC) {
            return a.start_date < b.start_date ? 1 : -1;
          } else {
            return b.start_date < a.start_date ? 1 : -1;
          }
        };
        break;
      case Sort.SORT_ROUND:
        if (sortLabel === "All") {
          sortKeyFn = (event) => event.roundTimes && event.roundGames[sortRoundName] ?
            event.roundTimes[sortRoundName] / event.roundGames[sortRoundName] :
            0;
        } else {
          sortKeyFn = (event) => event.winningRoundTimes && event.winningRoundGames[sortRoundName] ?
            event.winningRoundTimes[sortRoundName] / event.winningRoundGames[sortRoundName] :
            0;
        }
        sortFn = (a, b) => {
          const avgA = sortKeyFn(a);
          const avgB = sortKeyFn(b);
          if (avgA === 0) {
            return 1;
          } else if (avgB === 0) {
            return -1;
          }
          if (sortOrder === SortOrder.SORT_ORDER_DESC) {
            return avgA - avgB;
          } else {
            return avgB - avgA;
          }
        };
        break;
      case Sort.SORT_QUAL_STATS:
        sortKeyFn = (event) => {
          if (sortRoundName === 16) return event.lev16;
          else if (sortRoundName === 17) return event.lev17;
          else if (sortRoundName === 18) return event.lev18;
          else if (sortRoundName === 19) return event.lev19;
        };
        sortFn = (a, b) => {
          const avgA = sortKeyFn(a);
          const avgB = sortKeyFn(b);
          if (avgA === 0) {
            return 1;
          } else if (avgB === 0) {
            return -1;
          }
          if (sortOrder === SortOrder.SORT_ORDER_DESC) {
            return avgB - avgA;
          } else {
            return avgA - avgB;
          }
        };
        break;
      case Sort.SORT_QUAL_AVERAGE:
        sortKeyFn = (event) => {
          if (!('qualifiers' in event) || event.qualifiers.length === 0) return 0;
          return event.qualifiers.reduce((a, c) => a + c, 0)/event.qualifiers.length;
        }
        sortFn = (a, b) => {
          const avgA = sortKeyFn(a);
          const avgB = sortKeyFn(b);
          if (avgA === 0) {
            return 1;
          } else if (avgB === 0) {
            return -1;
          }
          if (sortOrder === SortOrder.SORT_ORDER_DESC) {
            return avgB - avgA;
          } else {
            return avgA - avgB;
          }
        };
        break;
      default:
    }
    let events = Array.from(eventMap.values());
    let prevValue = -10000;
    let rank = 0;
    return events
      .sort(sortFn)
      .map((event, index) => {
        if (sortKeyFn(event) !== prevValue) {
          prevValue = sortKeyFn(event);
          rank = index + 1;
        }
        event.rank = rank;
        return event;
      });
  }

  sortBy(sort, sortRoundName, sortLabel) {
    let sortOrder = SortOrder.SORT_ORDER_DESC;
    if (this.state.sort === sort && (!sortRoundName || this.state.sortRoundName === sortRoundName) && this.state.sortLabel === sortLabel) {
      if (this.state.sortOrder === SortOrder.SORT_ORDER_DESC) {
        sortOrder = SortOrder.SORT_ORDER_ASC;
      }
      if (this.state.sortOrder === SortOrder.SORT_ORDER_ASC) {
        sort = Sort.SORT_DEFAULT;
        sortOrder = SortOrder.SORT_ORDER_DESC;
      }
    }
    this.setState({
      sort,
      sortOrder,
      sortRoundName,
	  sortLabel
    });
  }



  render() {
    const { eventMap, currentYear, possibleYears, isLoaded, silverMode } = this.state;
    if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      const possibleLevels = new Set();
      let maxQual = 0;
      Array.from(eventMap.values())
        .forEach((event) => {
          if ("roundGames" in event) {
            Array.from(Object.getOwnPropertyNames(event.roundGames)).forEach((roundName) => possibleLevels.add(roundName));
          }
          if (event.lev19 > 0) maxQual = Math.max(maxQual, 19);
          else if (event.lev18 > 0) maxQual = Math.max(maxQual, 18);
          else if (event.lev17 > 0) maxQual = Math.max(maxQual, 17);
          else if (event.lev16 > 0) maxQual = Math.max(maxQual, 16);
        });
      let count = 1;
      const sortedEvents = this.sortEvents();
      const qualifiers = sortedEvents.slice().reverse().map((event) => ({
        name: event.name,
        score: qualifierAverageScore(event.qualifiers),
      })).filter((qualifier) => qualifier.score > 0);
                      let minQualifierScore = Number.MAX_SAFE_INTEGER, maxQualifierScore = 0;
                      qualifiers.forEach((qualifier) => {
                        if (qualifier.score > maxQualifierScore) {
                          maxQualifierScore = qualifier.score;
                        }
                        if (qualifier.score < minQualifierScore) {
                          minQualifierScore = qualifier.score;
                        }
                      });
                      const [minQualifierLevel] = splitVirusScore(scoreToVirusScore(minQualifierScore)),
                        [maxQualifierLevel] = splitVirusScore(scoreToVirusScore(maxQualifierScore));
      return (
        <div className={styles.Stats}>
          <Select
            name="year"
            value={currentYear}
            onChange={(event) => {
              this.props.history.push(`/stats/${event.target.value}?bracket=${silverMode ? "silver" : "gold"}`);
            }}
          >
            {["All"].concat(Array.from(possibleYears))
              .sort(function (a, b) {
                return b - a;
              })
              .map((year) => {
                return <MenuItem key={year} value={year === "All" ? 0 : year}>{year}</MenuItem>;
              })}
          </Select>
          &nbsp;
          <Select
            name="bracket"
            value={silverMode ? "silver" : "gold"}
            onChange={(event) => {
              this.props.history.push(`/stats/${currentYear}?bracket=${event.target.value}`);
            }}
          >
            <MenuItem key="gold" value="gold">Gold</MenuItem>
            <MenuItem key="silver" value="silver">Silver</MenuItem>
          </Select>
          <Typography variant="h3">{currentYear === 0 ? "" : currentYear} {silverMode ? "Silver" : "Gold"} Monthly Statistics</Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan="3" rowSpan={2 - (possibleLevels.size > 0 ? 0 : 1)}></TableCell>
                  {possibleLevels.size > 0 ?
                  (<TableCell
                    align="center"
                    colSpan={possibleLevels.size * 2}
                    className={styles.AverageTime}
                  >
                    Average Time
                  </TableCell>) : <></>}
                  <TableCell align="center" colSpan={5-(19-maxQual)} rowSpan={2 - (possibleLevels.size > 0 ? 0 : 1)} className={styles.AverageTimeWinner}>Qualifier Stats</TableCell>
                </TableRow>
                {possibleLevels.size > 0 ?
                (<TableRow>
                  {Array.from(possibleLevels)
                    .sort(function (a, b) {
                      return parseInt(a.split("-")[0])*parseInt(a.split("-")[1]) - parseInt(b.split("-")[0])*parseInt(b.split("-")[1]);
                    })
                    .map((roundName) => {
                      return <TableCell align="center" colSpan="2" className={styles.AverageTime}>
                          {roundName}
                        </TableCell>
                  })}
                </TableRow>) : <></>}
                <TableRow>
                  <TableCell align="right"></TableCell>
                  <TableCell>
                    {this.sortButton(Sort.SORT_DEFAULT, 'Event')}
                  </TableCell>
                  <TableCell>Finals
                  </TableCell>
                  {Array.from(possibleLevels)
                    .sort(function (a, b) {
                      return parseInt(a.split("-")[0])*parseInt(a.split("-")[1]) - parseInt(b.split("-")[0])*parseInt(b.split("-")[1]);
                    })
                    .map((roundName) => {
                      return <><TableCell align="right" className={styles.AverageTime}>
                          {this.sortButton(Sort.SORT_ROUND, "All", roundName)}
                        </TableCell>
                        <TableCell align="right" className={styles.AverageTimeWinner}>
                          {this.sortButton(Sort.SORT_ROUND, "Winner", roundName)}
                        </TableCell></>;
                  })}
                  <TableCell className={styles.AverageTime}>
                    {this.sortButton(Sort.SORT_QUAL_AVERAGE, 'Average')}
                  </TableCell>
                  {maxQual >= 16 ?
                  (<TableCell className={styles.AverageTimeWinner}>
                    {this.sortButton(Sort.SORT_QUAL_STATS, '16+', 16)}
                  </TableCell>) : <></>}
                  {maxQual >= 17 ?
                  (<TableCell className={styles.AverageTime}>
                    {this.sortButton(Sort.SORT_QUAL_STATS, '17+', 17)}
                  </TableCell>) : <></>}
                  {maxQual >= 18 ?
                  (<TableCell className={styles.AverageTimeWinner}>
                    {this.sortButton(Sort.SORT_QUAL_STATS, '18+', 18)}
                  </TableCell>) : <></>}
                  {maxQual >= 19 ?
                  (<TableCell className={styles.AverageTime}>
                    {this.sortButton(Sort.SORT_QUAL_STATS, '19+', 19)}
                  </TableCell>) : <></>}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedEvents
                  .map((event) => {
                    var winnerImage=undefined, runnerUpImage=undefined;
                    if ((event.finalsCount1 === 3 || (event.finalsCount1 === 2 && silverMode)) && event.finalist1.twitch_user) {
                      winnerImage = (
                        <Link to={"/players/" + event.finalist1.gamer_tag}>
                        <Image
                          src={event.finalist1.twitch_user.profile_image_url}
                          className={styles.PlayerImage}
                          title={event.finalist1.gamer_tag}
                        /></Link>);
                      if ('finalist2' in event) {
                        runnerUpImage = (
                          <Link to={"/players/" + event.finalist2.gamer_tag}>
                          <Image
                            src={event.finalist2.twitch_user.profile_image_url}
                            className={styles.PlayerImage}
                            title={event.finalist2.gamer_tag}
                          /></Link>);
                      }
                    } else if ((event.finalsCount2 === 3 || (event.finalsCount2 === 2 && silverMode)) && event.finalist1.twitch_user) {
                      winnerImage = (
                        <Link to={"/players/" + event.finalist2.gamer_tag}>
                        <Image
                          src={event.finalist2.twitch_user.profile_image_url}
                          className={styles.PlayerImage}
                          title={event.finalist2.gamer_tag}
                        /></Link>);
                      runnerUpImage = (
                        <Link to={"/players/" + event.finalist1.gamer_tag}>
                        <Image
                          src={event.finalist1.twitch_user.profile_image_url}
                          className={styles.PlayerImage}
                          title={event.finalist1.gamer_tag}
                        /></Link>);
                    }
                    return (
                      <TableRow
                        key={event.id}
                        className=""
                      >
                        <TableCell align="right">{event.rank === count++ ? event.rank : ""}</TableCell>
                        <TableCell>
                          <div className={styles.PlayerInfo}>
                            <Link to={"/events/" + event.slug}>
                              {event.name}
                            </Link>
                          </div>
                        </TableCell>
                        <TableCell><div className={styles.PlayerInfo}>
                          { winnerImage !== undefined ? (
                            <>{winnerImage}&nbsp;</>
                          ) : ""}
                          { event.finalsCount1 > 0 || event.finalsCount2 > 0 ? (
                            <>{Math.max(event.finalsCount1, event.finalsCount2)}-{Math.min(event.finalsCount1, event.finalsCount2)}</>
                          ) : ""}
                          { runnerUpImage !== undefined ? (
                            <>&nbsp;{runnerUpImage}</>
                          ) : ""}
                          { event.id === 'ac2963fc-be78-490a-aecb-235203aae40c' ? (
                            <span className={styles.Hidden}>Never Forget!
                            </span>
                          ) : ""}
                        </div></TableCell>
                        {Array.from(possibleLevels)
                          .sort(function (a, b) {
                            return parseInt(a.split("-")[0])*parseInt(a.split("-")[1]) - parseInt(b.split("-")[0])*parseInt(b.split("-")[1]);
                          })
                          .map((roundName) => {
                            return <><TableCell align="right" className={styles.AverageTime}>
                              {event.roundTimes && event.roundGames[roundName]
                                ? secondsToTime(
                                    event.roundTimes[roundName] / event.roundGames[roundName]
                                  )
                                : ""}
                            </TableCell>
                            <TableCell align="right" className={styles.AverageTimeWinner}>
                              {event.winningRoundTimes && event.winningRoundGames[roundName]
                                ? secondsToTime(
                                    event.winningRoundTimes[roundName] / event.winningRoundGames[roundName]
                                  )
                                : ""}
                            </TableCell></>;
                          })}
                        <TableCell className={styles.AverageTime}>
                          <Tooltip
                            key={"qa" + event.id}
                            title={'qualifiers' in event && event.qualifiers && event.qualifiers.length > 0 ? qualifierAverageScore(event.qualifiers) : ""}
                          >
                            <div>{'qualifiers' in event && event.qualifiers && event.qualifiers.length > 0 ? qualifierAverageVirusScore(event.qualifiers) : ""}</div>
                          </Tooltip>
                        </TableCell>
                        {maxQual >= 16 ?
                        (<TableCell className={styles.AverageTimeWinner}>
                            {event.lev16 ? event.lev16 : ""}
                        </TableCell>) : <></>}
                        {maxQual >= 17 ?
                        (<TableCell className={styles.AverageTime}>
                            {event.lev17 ? event.lev17 : ""}
                        </TableCell>) : <></>}
                        {maxQual >= 18 ?
                        (<TableCell className={styles.AverageTimeWinner}>
                            {event.lev18 ? event.lev18 : ""}
                        </TableCell>) : <></>}
                        {maxQual >= 19 ?
                        (<TableCell className={styles.AverageTime}>
                            {event.lev19 ? event.lev19 : ""}
                        </TableCell>) : <></>}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.Chart}>
            <Line data={{
                labels: qualifiers.map((qualifier) => qualifier.name),
                datasets: [{
                  label: 'Qualifiers (Avg)',
                  data: qualifiers.map((qualifier) => qualifier.score),
                }],
              }} options={{
                maintainAspectRatio: false,
                scales: {
                  y: {
                    max: virusScoreToScore(`${maxQualifierLevel}(0)`),
                    min: virusScoreToScore(`${minQualifierLevel - 1}(0)`),
                    afterBuildTicks: (axis) => {
                      axis.ticks = [];
                      for (let i = minQualifierLevel - 1; i <= maxQualifierLevel; i++) {
                        axis.ticks.push({value: virusScoreToScore(`${i}(0)`)});
                      }
                    },
                    ticks: {
                      callback: (value) => splitVirusScore(scoreToVirusScore(value))[0]
                    },
                  }
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: (context) => scoreToVirusScore(parseInt(context.parsed.y), true),
                    }
                  }
                }
              }} />
          </div>
        </div>
      );
    }
  }
}

function qualifierAverageScore(qualifiers) {
  return qualifiers && qualifiers.length > 0 ? (qualifiers.reduce((a, c) => a + c, 0) / qualifiers.length).toFixed(3) : 0;
}

function qualifierAverageVirusScore(qualifiers) {
  return scoreToVirusScore(parseInt(qualifiers.reduce((a, c) => a + c, 0)/qualifiers.length), true);
}

export default Stats