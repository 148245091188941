import Chip from '@mui/joy/Chip';
import BadgeIcon from '@mui/icons-material/LocalPolice';
import Tooltip from '@mui/joy/Tooltip';

import styles from './PersonalBestBadgeGraphql.module.scss';
import {scoreToVirusScore} from './utils/virus_score';

interface Props {
  previousPersonalBestScore: number | undefined;
}

export function PersonalBestBadge(props: Props) {
  return (
    <Tooltip
      size="sm"
      title={
        props.previousPersonalBestScore === undefined
          ? 'No previous PB'
          : `Previous PB: ${scoreToVirusScore(
              props.previousPersonalBestScore,
              true /* shouldPad */,
            )} = ${props.previousPersonalBestScore}`
      }>
      <Chip
        size="sm"
        color="warning"
        className={styles.Chip}
        startDecorator={<BadgeIcon className={styles.Icon} />}
        sx={(theme) => ({
          backgroundColor: theme.palette.warning.outlinedColor,
          color: theme.palette.background.body,
        })}>
        PB
      </Chip>
    </Tooltip>
  );
}
