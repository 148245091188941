import {useQuery} from '@apollo/client';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import Sheet from '@mui/joy/Sheet';
import Tab from '@mui/joy/Tab';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import Tabs from '@mui/joy/Tabs';
import TimerIcon from '@mui/icons-material/Timer';
import Typography from '@mui/joy/Typography';

import {Bracket} from './BracketGraphql';
import {DarkLightModeIcon} from './DarkLightModeIcon';
import {Qualifiers} from './QualifiersGraphql';
import {getQualifiers} from './event_utils';
import {gql} from './types/gql';

import styles from './EventGraphql.module.scss';

const QUERY = gql(`
  query GetEvent($slug: String!) {
    event(slug: $slug) {
      name
      startDate
      qualifyingAttempts {
        attempt
        isDrop
        player {
          id
          gamerTag
          twitchUser {
            profileImageUrl
          }
          qualifyingAttempts {
            attempt
            score
            event {
              id
              startDate
            }
          }
        }
        score
        timeToLastLevelSeconds
      }
      rounds {
        levelStart
        levelEnd
        name
        numWinsRequired
        order
        matches {
          matchNumber
          player1 {
            id
            gamerTag
          }
          player2 {
            id
            gamerTag
          }
          games {
            gameNumber
            player1Score
            player1TimeSeconds
            player2Score
            player2TimeSeconds
            winningPlayerNumberForced
          }
        }
      }
      seeds {
        seed
        player {
          id
        }
      }
    }
  }
`);

interface Props {
  slug: string;
}

export function Event(props: Props) {
  const {loading, error, data} = useQuery(QUERY, {
    variables: {
      slug: props.slug,
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error || !data) {
    return <div>Error: {error?.message ?? 'Unknown'}</div>;
  }
  if (!data.event) {
    return <div>Error: Not found</div>;
  }

  const qualifiers = getQualifiers(data.event.qualifyingAttempts);

  return (
    <Sheet className={styles.Sheet}>
      <div className={styles.Header}>
        <Typography level="h1" className={styles.Title}>
          {data.event.name}
        </Typography>
        <DarkLightModeIcon />
      </div>
      <Tabs size="sm">
        <TabList>
          <Tab>
            <LeaderboardIcon fontSize="small" />
            Bracket
          </Tab>
          <Tab>
            <TimerIcon fontSize="small" />
            Qualifiers
          </Tab>
        </TabList>
        <TabPanel value={0} className={styles.TabPanel}>
          <Bracket rounds={data.event.rounds} seeds={data.event.seeds} />
        </TabPanel>
        <TabPanel
          value={1}
          className={`${styles.TabPanel} ${styles.TabPanelQualifiers}`}>
          <Qualifiers
            qualifiers={qualifiers}
            startDate={data.event.startDate}
          />
        </TabPanel>
      </Tabs>
    </Sheet>
  );
}
