import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

const Admin = (props) => {
  return (
    <div className="AdminContainer">
      <Typography variant="h3">Admin</Typography>
      <ul>
        <li>
          <Link to="/admin/events">Events</Link>
        </li>
        <li>
          <Link to="/admin/players">Players</Link>
        </li>
        <li>
          <Link to="/admin/twitch_users">Twitch Users</Link>
        </li>
      </ul>
    </div>
  );
};

export default Admin;
