import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Tooltip from '@mui/joy/Tooltip';
import Typography from '@mui/joy/Typography';
import {styled} from '@mui/joy/styles';

// @ts-ignore
import Image from './Image';
import {Link} from './Link';
import {
  getRenderableBracket,
  RenderableGame,
  RenderableMatch,
  RenderablePlayer,
  RenderableRound,
} from './bracket_utils';
import {Round as RoundData, Seed} from './event_utils';

import styles from './BracketGraphql.module.scss';

interface Props {
  rounds: RoundData[];
  seeds: Seed[];
}

export function Bracket(props: Props) {
  const renderableBracket = getRenderableBracket(props.rounds, props.seeds);
  return (
    <div className={styles.Bracket}>
      {renderableBracket.rounds.map((round) => (
        <Round round={round} key={round.order} />
      ))}
    </div>
  );
}

interface RoundProps {
  round: RenderableRound;
}

function Round(props: RoundProps) {
  return (
    <div className={styles.RoundContainer}>
      <Card variant="soft" className={styles.RoundHeading}>
        <CardContent>
          <Typography level="title-lg">{props.round.title}</Typography>
          <Typography level="body-sm" className={styles.RoundSubtitle}>
            {props.round.subtitle}
          </Typography>
        </CardContent>
      </Card>
      <div className={styles.Round}>
        {props.round.matches.map((match) => (
          <Match match={match} key={match.matchNumber} />
        ))}
      </div>
    </div>
  );
}

interface MatchProps {
  match: RenderableMatch;
}

function Match(props: MatchProps) {
  return (
    <Card
      variant="plain"
      className={styles.Match}
      sx={(theme) => ({
        borderColor: theme.palette.divider,
      })}>
      <CardOverflow>
        <MatchPlayer player={props.match.player1} isTop />
        <MatchPlayer player={props.match.player2} />
      </CardOverflow>
    </Card>
  );
}

interface MatchPlayerProps {
  player: RenderablePlayer | undefined;
  isTop?: boolean;
}

function MatchPlayer(props: MatchPlayerProps) {
  const Div = styled('div')(({theme}) => ({
    backgroundColor: props.player?.isMatchWinner
      ? theme.palette.warning.softBg
      : '',
    '--joy-fontWeight-md': props.player?.isMatchWinner ? 700 : '',
  }));
  return (
    <Div
      className={`${styles.MatchPlayer} ${
        props.isTop ? styles.MatchPlayerTop : styles.MatchPlayerBottom
      }`}>
      <Typography
        className={styles.Seed}
        level="body-xs"
        sx={(theme) => ({
          backgroundColor: props.player?.isMatchWinner
            ? theme.palette.warning.softHoverBg
            : theme.palette.background.level2,
        })}>
        {props.player?.seed ?? <>&nbsp;</>}
      </Typography>
      <Typography className={styles.GamerTag} level="body-xs">
        {props.player?.gamerTag ? (
          <Link to={`/players/${props.player.gamerTag}`}>
            {props.player.gamerTag}
          </Link>
        ) : (
          <>&nbsp;</>
        )}
      </Typography>
      <div className={styles.Games}>
        {props.player?.gamesWon.map((game) => (
          <Game game={game} key={game.gameNumber} />
        ))}
      </div>
    </Div>
  );
}

interface GameProps {
  game: RenderableGame;
}

function Game(props: GameProps) {
  return (
    <Tooltip
      size="sm"
      variant="soft"
      title={
        <div className={styles.GameTooltip}>
          <Typography level="title-sm">Game {props.game.gameNumber}</Typography>
          <Typography level="body-xs">
            {props.game.winningPlayerGamerTag}: {props.game.winningPlayerStats}
          </Typography>
          <Typography level="body-xs">
            {props.game.losingPlayerGamerTag}: {props.game.losingPlayerStats}
          </Typography>
        </div>
      }>
      <div>
        <Image src="/crown.png" isPixelated />
      </div>
    </Tooltip>
  );
}
