import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";

import Image from "./Image";
import {apiFetch} from "./utils/api_fetch";
import { scoreToVirusScore } from "./utils/virus_score";

import styles from "./QualRecords.module.scss";

class QualRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quals: [],
      playerMap: {},
      eventMap: {},
      currentYear: 0,
      possibleYears: new Set(),
      isLoaded: false,
      numberToShow: 20,
    };
  }

  loadQualRecords(currentYear) {
    Promise.all([
      apiFetch("qualifying-attempts"),
      apiFetch("events"),
      apiFetch("players"),
    ]).then(([qualsResponse, eventsResponse, playersResponse]) => {
      const possibleYears = new Set(
        eventsResponse.map((event) =>
          event.start_date.replace(/([0-9]{4}).*/, "$1")
        )
      );
      if (currentYear === null) {
        currentYear = "0";
      }
      const playerMap = new Map(
        playersResponse.map((player) => [player.id, player])
      );
      const eventMap = new Map(
        eventsResponse
          .filter(
            (event) => currentYear === "0" || event.name.includes(currentYear)
          )
          .map((event) => [event.id, event.name])
      );
      const qualMap = new Map();
      qualsResponse.forEach((qual) => {
        const key = qual.event_id + qual.player_id;
        if (eventMap.has(qual.event_id) && (!qualMap.has(key) || qual.attempt > qualMap.get(key).attempt)) {
          qual.score_notation = scoreToVirusScore(qual.score, true);
          qualMap.set(key, qual);
        }
      });
      let quals = Array.from(qualMap.values());
      this.setState({
        quals,
        playerMap,
        eventMap,
        currentYear,
        possibleYears,
        isLoaded: true,
      });
    });
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    this.loadQualRecords(params.get("year"));
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      const params = new URLSearchParams(this.props.location.search);
      this.loadQualRecords(params.get("year"));
    }
    if (this.state.numberToShow !== 20) {
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
  }

  render() {
    const { quals, playerMap, eventMap, currentYear, possibleYears, isLoaded, numberToShow } = this.state;
    if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      let count = 0;
      let rank = 0;
      let prevScore = -1;
      const playerPbs = new Map();
      return (
        <div className={styles.RecordsContainer}>
          Year:{" "}
          <Select
            name="year"
            value={currentYear}
            onChange={(event) => {
              this.props.history.push(
                `/qualrecords?year=${event.target.value}`
              );
            }}
          >
            <MenuItem value={0}>All</MenuItem>
            {Array.from(possibleYears)
              .sort(function (a, b) {
                return b - a;
              })
              .map((year) => {
                return <MenuItem value={year}>{year}</MenuItem>;
              })}
          </Select>
          <Typography variant="h3">Top Qualifying Scores</Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Rank</TableCell>
                  <TableCell></TableCell>
                  <TableCell>Score</TableCell>
                  <TableCell>Player</TableCell>
                  <TableCell>Event</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {quals
                  .sort(function (a, b) {
                    return b.score - a.score;
                  })
                  .slice(0, numberToShow)
                  .map((qual) => {
                    count++;
                    if (qual.score !== prevScore) {
                      prevScore = qual.score;
                      rank = count;
                    }
                    const player = playerMap.get(qual.player_id);
                    const playerImage = player.twitch_user ? (
                      <Image
                        src={player.twitch_user.profile_image_url}
                        className={styles.PlayerImage}
                      />
                    ) : (
                      ""
                    );
                    const eventName = eventMap.get(qual.event_id);
                    let pbBadge = <></>
                    if (!playerPbs.has(qual.player_id) || playerPbs.get(qual.player_id) === qual.score) {
                      playerPbs.set(qual.player_id, qual.score);
                      pbBadge = <>
                        <Chip style={{"backgroundColor": "#fffde7"}} size="small" label="PB" avatar={<Avatar src="/badge.png" style={{"backgroundColor": "#fdd835"}}/>}/>
                      </>
                    }
                    return (
                      <TableRow key={qual.id}>
                        <TableCell align="right">{rank === count ? rank : ""}</TableCell>
                        <TableCell>
                          {pbBadge}
                        </TableCell>
                        <TableCell align="right">
                          {qual.score_notation} = {qual.score}
                        </TableCell>
                        <TableCell>
                          <div className={styles.PlayerInfo}>
                            {playerImage}
                            <Link to={"/players/" + qual.player_id}>
                              {player.gamer_tag}
                            </Link>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={styles.EventInfo}>
                            <Link to={"/events/" + qual.event_id}>
                              {eventName}
                            </Link>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          { numberToShow < quals.length ?
          (<>
          <br></br>
          <center>
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                this.setState({
                  quals,
                  playerMap,
                  eventMap,
                  isLoaded: true,
                  numberToShow: numberToShow+20
                })
              }
            >
              Show More
            </Button>
          </center></>) : ""}
          <div style={{ float:"left", clear: "both" }}
             ref={(el) => { this.messagesEnd = el; }}>
          </div>
        </div>
      );
    }
  }
}

export default QualRecord;
