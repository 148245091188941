import Cleave from "cleave.js/react";
import TextField from "@mui/material/TextField";

const CleaveTextField = ({ inputRef, ...props }) => (
  <Cleave
    {...props}
    htmlRef={inputRef}
    options={{ numericOnly: true, blocks: [2, 2, 0], delimiters: ["(", ")"] }}
  />
);

export const VirusScoreTextField = (props) => (
  <TextField
    InputProps={{ inputComponent: CleaveTextField }}
    placeholder="LL(VV)"
    InputLabelProps={{ shrink: true }}
    {...props}
  />
);
