import React, { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import Alert from "@mui/lab/Alert";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";

import {apiFetch} from "./utils/api_fetch";
import { ComboBox } from "./ComboBox";
import { TimeTextField } from "./TimeTextField";
import { timeToSeconds, secondsToTime, isValidTime } from "./utils/time";
import { VirusScoreTextField } from "./VirusScoreTextField";
import {
  virusScoreToScore,
  scoreToVirusScore,
  isValidVirusScore,
} from "./utils/virus_score";

import styles from "./MatchDialog.module.scss";

const MatchDialog = ({
  open,
  closeDialog,
  seeds,
  playerMap,
  match,
  round,
  updateBracket,
  showSnackbar,
}) => {
  // state

  const [validationErrorText, setValidationErrorText] = useState(""); // string
  const [inProgress, setInProgress] = useState(false); // bool
  const [player1Id, setPlayer1Id] = useState(match ? match.player_1_id : null);
  const [player2Id, setPlayer2Id] = useState(match ? match.player_2_id : null);
  const [games, setGames] = useState(
    JSON.parse(JSON.stringify(match ? match.games : [])).map((game) => {
      game.player_1_time_seconds = secondsToTime(
        game.player_1_time_seconds,
        true /* shouldPad */
      );
      game.player_1_score = scoreToVirusScore(
        game.player_1_score,
        true /* shouldPad */,
        round.level_start
      );
      game.player_2_time_seconds = secondsToTime(
        game.player_2_time_seconds,
        true /* shouldPad */
      );
      game.player_2_score = scoreToVirusScore(
        game.player_2_score,
        true /* shouldPad */,
        round.level_start
      );
      return game;
    })
  );

  // calculated state

  const validationError = validationErrorText ? (
    <Alert severity="error">{validationErrorText}</Alert>
  ) : (
    ""
  );
  const players = seeds
    .filter((seed) => playerMap.has(seed.player_id))
    .map((seed) => playerMap.get(seed.player_id));

  // handlers

  const handlePlayer1IdChange = (event, value) => {
    setPlayer1Id(value ? value.id : null);
  };

  const handlePlayer2IdChange = (event, value) => {
    setPlayer2Id(value ? value.id : null);
  };

  const dismiss = () => {
    // reset values?
    closeDialog();
  };

  const update = async () => {
    if (!isValid()) {
      return;
    }
    setInProgress(true);
    const requests = [];
    requests.push(
      apiFetch(`matches/${match.id}`, {
        method: "PUT",
        body: JSON.stringify({
          ...match,
          player_1_id: player1Id,
          player_2_id: player2Id,
        }),
      })
    );
    requests.push(
      ...games.map((game, i) => {
        const id = game.id;
        return apiFetch("games" + (id ? `/${id}` : ""), {
          method: id ? "PUT" : "POST",
          body: JSON.stringify({
            id,
            match_id: match.id,
            player_1_score: virusScoreToScore(
              game.player_1_score,
              round.level_start
            ),
            player_1_time_seconds: timeToSeconds(game.player_1_time_seconds),
            player_2_score: virusScoreToScore(
              game.player_2_score,
              round.level_start
            ),
            player_2_time_seconds: timeToSeconds(game.player_2_time_seconds),
            game_number: i + 1,
            winning_player_number_forced: game.winning_player_number_forced,
          }),
        });
      })
    );
    const existingGameIds = match.games.map((game) => game.id);
    const newGameIds = games.map((game) => game.id).filter((id) => !!id);
    requests.push(
      ...existingGameIds
        .filter((gameId) => !newGameIds.includes(gameId))
        .map((id) =>
          apiFetch(`games/${id}`, {
            method: "DELETE",
          })
        )
    );
    try {
      await Promise.all(requests);
      showSnackbar("Match updated successfully.", "success");
      dismiss();
    } catch (error) {
      console.log(error);
      showSnackbar("An error occurred. Please try again.", "error");
    }
    setInProgress(false);
  };

  const isValid = () => {
    for (const [gameIndex, game] of games.entries()) {
      for (const player of [1, 2]) {
        if (!isValidTime(game[`player_${player}_time_seconds`])) {
          setValidationErrorText(
            `Invalid value for Game ${gameIndex + 1} Player ${player} time`
          );
          return false;
        }
        if (!isValidVirusScore(game[`player_${player}_score`])) {
          setValidationErrorText(
            `Invalid value for Game ${gameIndex + 1} Player ${player} score`
          );
          return false;
        }
      }
    }
    return true;
  };

  const addGame = () => {
    setGames(
      games.concat([
        {
          player_1_score: "",
          player_1_time_seconds: "",
          player_2_score: "",
          player_2_time_seconds: "",
          winning_player_number_forced: 0,
        },
      ])
    );
  };

  const deleteGame = (i) => {
    setGames(games.filter((_, index) => index !== i));
  };

  // renderable elements

  // TODO(aparticka): Check if match has already been won instead
  const addGameButton =
    games.length >= round.num_wins_required * 2 - 1 ? (
      ""
    ) : (
      <Button
        className={styles.AddGameButton}
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={addGame}
      >
        Add game
      </Button>
    );

  const updateGameField = (value, gameIndex, fieldName) => {
    const newGames = games.map((game, index) => {
      if (index === gameIndex) {
        game[fieldName] = value;
      }
      return game;
    });
    setGames(newGames);
  };

  const handleClose = () => {
    dismiss();
  };

  // render

  return (
    <Dialog open={open} onClose={handleClose} className={styles.MatchDialog}>
      <DialogContent>
        <div className={styles.Content}>
          <ComboBox
            options={players}
            optionLabelField="gamer_tag"
            className={styles.Input}
            label="Player 1"
            onChange={handlePlayer1IdChange}
            value={players.find((player) => player.id === player1Id) || null}
          />
          <ComboBox
            options={players}
            optionLabelField="gamer_tag"
            className={styles.Input}
            label="Player 2"
            onChange={handlePlayer2IdChange}
            value={players.find((player) => player.id === player2Id) || null}
          />
          <div className={styles.Games}>
            {games.map((game, i) => {
              return (
                <div
                  key={i}
                  className={styles.Game}
                  data-name={`Game ${i + 1}`}
                >
                  <div className={styles.Player}>
                    <TimeTextField
                      label="Player 1 time"
                      value={game.player_1_time_seconds}
                      onChange={(event) =>
                        updateGameField(
                          event.target.value,
                          i,
                          "player_1_time_seconds"
                        )
                      }
                      className={styles.Input}
                    />
                    <VirusScoreTextField
                      label="Player 1 score"
                      value={game.player_1_score}
                      onChange={(event) =>
                        updateGameField(event.target.value, i, "player_1_score")
                      }
                      className={styles.Input}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={game.winning_player_number_forced === 1}
                          onChange={(event) =>
                            updateGameField(
                              event.target.checked ? 1 : 0,
                              i,
                              "winning_player_number_forced"
                            )
                          }
                        />
                      }
                      label="Force win"
                    />
                  </div>
                  <div className={styles.Player}>
                    <TimeTextField
                      label="Player 2 time"
                      value={game.player_2_time_seconds}
                      onChange={(event) =>
                        updateGameField(
                          event.target.value,
                          i,
                          "player_2_time_seconds"
                        )
                      }
                      className={styles.Input}
                    />
                    <VirusScoreTextField
                      label="Player 2 score"
                      value={game.player_2_score}
                      onChange={(event) =>
                        updateGameField(event.target.value, i, "player_2_score")
                      }
                      className={styles.Input}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={game.winning_player_number_forced === 2}
                          onChange={(event) =>
                            updateGameField(
                              event.target.checked ? 2 : 0,
                              i,
                              "winning_player_number_forced"
                            )
                          }
                        />
                      }
                      label="Force win"
                    />
                  </div>
                  <IconButton
                    className={styles.DeleteButton}
                    variant="contained"
                    onClick={() => deleteGame(i)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              );
            })}
          </div>
          {addGameButton}
        </div>
        {validationError}
      </DialogContent>
      <DialogActions>
        <Button disabled={inProgress} onClick={() => dismiss()}>
          Cancel
        </Button>
        <Button color="primary" disabled={inProgress || (games.length > 0 && (player1Id === null || player2Id === null))} onClick={() => update()}>
          Update
        </Button>
      </DialogActions>
      <LinearProgress
        className={styles.Progress + (inProgress ? "" : ` ${styles.isHidden}`)}
      />
    </Dialog>
  );
};

export default MatchDialog;
