import {GetEventQuery} from './types/graphql';

type EventType = NonNullable<GetEventQuery['event']>;
export type QualifyingAttempt = EventType['qualifyingAttempts'][0];
export type Player = QualifyingAttempt['player'];
export type Seed = EventType['seeds'][0];
export type Round = EventType['rounds'][0];
export type Match = Round['matches'][0];
export type MatchPlayer = Match['player1'];
export type Game = Match['games'][0];

export interface Qualifier {
  player: Player;
  qualifyingAttemptMap: Record<number, QualifyingAttempt>;
}

export function getQualifiers(
  qualifyingAttempts: QualifyingAttempt[],
): Qualifier[] {
  const playerIdQualifierMap: Record<string, Qualifier> = {};
  qualifyingAttempts.forEach((qualifyingAttempt) => {
    const playerId = qualifyingAttempt.player.id;
    if (playerIdQualifierMap[playerId] === undefined) {
      playerIdQualifierMap[playerId] = {
        player: qualifyingAttempt.player,
        qualifyingAttemptMap: {},
      };
    }
    playerIdQualifierMap[playerId]!.qualifyingAttemptMap![
      qualifyingAttempt.attempt
    ] = qualifyingAttempt;
  });
  return Object.values(playerIdQualifierMap).sort((a, b) => {
    const isDropA = isDrop(a);
    const isDropB = isDrop(b);
    if (isDropA === isDropB) {
      const scoreA = getScore(a);
      const scoreB = getScore(b);
      const scoreDiff = scoreB - scoreA;
      if (scoreDiff === 0) {
        const timeToLastLevelSecondsA = getTimeToLastLevelSeconds(a);
        const timeToLastLevelSecondsB = getTimeToLastLevelSeconds(b);
        return timeToLastLevelSecondsA - timeToLastLevelSecondsB;
      }
      return scoreDiff;
    }
    return isDropA ? 1 : -1;
  });
}

export function isDrop(qualifier: Qualifier): boolean {
  return (
    Object.values(qualifier.qualifyingAttemptMap).filter(
      (qualifyingAttempt) => qualifyingAttempt.isDrop,
    ).length > 0
  );
}

export function getScore(qualifier: Qualifier): number {
  return (
    qualifier.qualifyingAttemptMap[2]?.score ??
    qualifier.qualifyingAttemptMap[1]?.score ??
    0
  );
}

function getTimeToLastLevelSeconds(qualifier: Qualifier): number {
  if (qualifier.qualifyingAttemptMap[2]) {
    return qualifier.qualifyingAttemptMap[2].timeToLastLevelSeconds ?? 0;
  }
  return qualifier.qualifyingAttemptMap[1]?.timeToLastLevelSeconds ?? 0;
}
