import { Redirect, Route } from "react-router-dom";

const AdminRoute = ({
  component,
  user,
  isFetchingUser,
  hasFetchedUser,
  ...rest
}) => {
  return isFetchingUser || !hasFetchedUser ? (
    <div>Loading...</div>
  ) : user && user.role_name === "Admin" ? (
    <Route component={component} {...rest} />
  ) : (
    <Redirect
      to={{
        pathname: "/",
        state: {
          notification:
            "You tried to access a restricted part of the site. If you feel you should have access, contact an administrator to elevate your privileges.",
        },
      }}
    />
  );
};

export default AdminRoute;
