import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import LockIcon from "@mui/icons-material/Lock";

import styles from "./AdminButton.module.scss";

function AdminButton(props) {
  return (
    <Link to="/admin" className={styles.AdminLink}>
      <Button className={styles.AdminButton}>
        <LockIcon className={styles.AdminIcon} />
        <span className={styles.AdminText}>Admin</span>
      </Button>
    </Link>
  );
}

export default AdminButton;
