import {ApiError} from '../errors/ApiError';
import {BASE_URL} from '../config/api.config';

export interface ApiFetchOptions {
  headers?: Record<string, string>;
}

export async function apiFetch(
  path: string,
  options: ApiFetchOptions = {},
): Promise<Record<string, any>> {
  const sessionId = window.localStorage.getItem('sessionId');
  if (!options.headers) {
    options.headers = {};
  }
  if (sessionId !== null) {
    options.headers['Authorization'] = 'Bearer ' + sessionId;
  }
  if (!options.headers['Content-Type']) {
    options.headers['Content-Type'] = 'application/json';
  }
  const response = await fetch(`${BASE_URL}${path}`, options);
  const {data, errors} = await response.json();
  const isInvalidSessionId =
    errors.filter((error: any) => error.status === 401).length > 0;
  if (isInvalidSessionId) {
    window.localStorage.removeItem('sessionId');
  }
  if (data) {
    return data;
  }
  throw new ApiError(errors);
}
