import React from "react";

class Session extends React.Component {
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    window.localStorage.setItem("sessionId", id);
    this.props.history.push("/");
  }

  render() {
    return <></>;
  }
}

export default Session;
