import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";

import Image from "./Image";
import TwitchChip from "./TwitchChip";
import {apiFetch} from "./utils/api_fetch";
import { secondsToTime } from "./utils/time";
import { scoreToVirusScore } from "./utils/virus_score";
import {getWinner} from "./utils/get_winner";
import Standings from "./Standings.js";

import styles from "./Player.module.scss";

class Player extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      player: {},
      isLoaded: false,
      qualifiers: [],
      events: [],
      ranking: undefined,
      opponents: [],
      opponent: null,
      playerStats: {},
      possibleYears: [],
      currentYear: null,
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    this.updatePlayer(params.get("year"), params.get("opponent"));
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search || this.props.match.params.idOrGamerTag !== prevProps.match.params.idOrGamerTag) {
      const params = new URLSearchParams(this.props.location.search);
      this.updatePlayer(params.get("year"), params.get("opponent"));
    }
  }

  updatePlayer(currentYear, opponent) {
    Promise.all([
      apiFetch(`players/${this.props.match.params.idOrGamerTag}`),
      apiFetch(`players/${this.props.match.params.idOrGamerTag}/qualifying-attempts`),
      apiFetch("events"),
      apiFetch(`players/${this.props.match.params.idOrGamerTag}/matches`),
      apiFetch("rounds"),
      apiFetch("seeds"),
      apiFetch("players"),
      Standings.getPlayerMapCurrentYear(),
      Standings.getPlayerStats(this.props.match.params.idOrGamerTag),
    ]).then(
      ([
        playerResponse,
        qualifyingAttemptsResponse,
        eventsResponse,
        matchesResponse,
        roundsResponse,
        seedsResponse,
        playersResponse,
        standings,
        playerStats,
      ]) => {
        Promise.all(
          matchesResponse.map((match) =>
            apiFetch(`matches/${match.id}/games`).then((gamesResponse) => ({
              match,
              games: gamesResponse,
            }))
          )
        ).then((gamesResponses) => {
          const eventMap = {};
          for (const event of eventsResponse) {
            eventMap[event.id] = event;
          }
          const possibleYears = new Set();
          const qualifiers = [];
          let best = 0;
          let prevBest = 0;
          for (const qualifyingAttempt of qualifyingAttemptsResponse.sort(
            (a, b) => {
              const eventA = eventMap[a.event_id];
              const eventB = eventMap[b.event_id];
              const diff = eventA.start_date.localeCompare(eventB.start_date);
              if (diff === 0) {
                return a.attempt - b.attempt;
              }
              return diff;
            }
          )) {
            qualifyingAttempt.event = eventMap[qualifyingAttempt.event_id];
            if (
              qualifiers.length > 0 &&
              qualifiers[qualifiers.length - 1].event_id ===
                qualifyingAttempt.event_id
            ) {
              qualifyingAttempt.lastQualifyingAttempt =
                qualifiers[qualifiers.length - 1];
              qualifiers[qualifiers.length - 1] = qualifyingAttempt;
              qualifyingAttempt.pb = false;
              if (qualifyingAttempt.lastQualifyingAttempt.score === best) {
                best = prevBest;
              }
            } else {
              qualifiers.push(qualifyingAttempt);
              possibleYears.add(parseInt(qualifyingAttempt.event.start_date.replace(/([0-9]{4}).*/, "$1")));
            }
            if (qualifyingAttempt.score >= best) {
              qualifyingAttempt.pb = true;
              prevBest = best;
              best = qualifyingAttempt.score;
            } else {
              qualifyingAttempt.pb = false;
            }
          }

          if (currentYear === null) {
            currentYear = Math.max.apply(this, [...possibleYears]);
          } else {
            currentYear = parseInt(currentYear);
          }
          if (opponent === null) {
            opponent = "All";
          }

          qualifiers.reverse();
          const roundMap = {};
          for (const round of roundsResponse) {
            roundMap[round.id] = round;
          }
          const playerMap = {};
          for (const player of playersResponse) {
            playerMap[player.id] = player;
          }
          const gameMap = {};
          for (const gamesResponse of gamesResponses) {
            gameMap[gamesResponse.match.id] = gamesResponse.games;
          }
          const seedMap = {};
          for (const seed of seedsResponse) {
            if (!seedMap[seed.event_id]) {
              seedMap[seed.event_id] = {};
            }
            seedMap[seed.event_id][seed.player_id] = seed;
          }
          const events = [];
          const possibleOpponents = new Set();
          let date = new Date();
          date.setFullYear( date.getFullYear() - 1 );
          for (const match of matchesResponse.sort((a, b) => {
            const roundA = roundMap[a.round_id];
            const roundB = roundMap[b.round_id];
            const eventA = eventMap[roundA.event_id];
            const eventB = eventMap[roundB.event_id];
            const eventDiff = eventB.start_date.localeCompare(
              eventA.start_date
            );
            if (eventDiff !== 0) {
              return eventDiff;
            }
            return roundA.order - roundB.order;
          })) {
            match.round = roundMap[match.round_id];
            match.event = eventMap[match.round.event_id];
            const opponent =
              playerMap[
                match.player_1_id === playerResponse.id
                  ? match.player_2_id
                  : match.player_1_id
              ];
            if (opponent) {
              if (!('years' in opponent)) {
                opponent['years'] = new Set();
              }
              opponent['years'].add(parseInt(match.event.start_date.replace(/([0-9]{4}).*/, "$1")));
              if (Date.parse(match.event.start_date) > date) {
                opponent['years'].add(-1);
              }
              possibleOpponents.add(opponent);
            }
            match.opponent = opponent ? Object.assign({}, opponent) : null;
            match.games = gameMap[match.id];
            match.winner = getWinner(match, match.round);
            match.numGamesWon = match.games.filter((game) =>
              match.player_1_id === playerResponse.id
                ? game.winning_player_number_forced === 1 ||
                  (game.winning_player_number_forced === 0 && (
                    !game.player_2_time_seconds ||
                    (game.player_1_time_seconds &&
                      game.player_1_time_seconds < game.player_2_time_seconds)
                  ))
                : game.winning_player_number_forced === 2 ||
                  (game.winning_player_number_forced === 0 && (
                    !game.player_1_time_seconds ||
                    (game.player_2_time_seconds &&
                      game.player_2_time_seconds < game.player_1_time_seconds)
                  ))
            ).length;
            match.seed = seedMap[match.event.id][playerResponse.id];
            if (match.opponent) {
              match.opponent.numGamesWon = match.games.filter((game) =>
                match.player_1_id !== playerResponse.id
                ? game.winning_player_number_forced === 1 ||
                  (game.winning_player_number_forced === 0 && (
                    !game.player_2_time_seconds ||
                    (game.player_1_time_seconds &&
                      game.player_1_time_seconds < game.player_2_time_seconds)
                  ))
                : game.winning_player_number_forced === 2 ||
                  (game.winning_player_number_forced === 0 && (
                    !game.player_1_time_seconds ||
                    (game.player_2_time_seconds &&
                      game.player_2_time_seconds < game.player_1_time_seconds)
                  ))
              ).length;
              match.opponent.seed = seedMap[match.event.id][match.opponent.id];
            }
            if (
              events.length === 0 ||
              events[events.length - 1].id !== match.event.id
            ) {
              match.event.matches = [];
              events.push(match.event);
            }
            events[events.length - 1].matches.push(match);
          }
          
          const search_results = Array.from(standings.values()).filter((player) => player.id === this.props.match.params.idOrGamerTag || player.gamer_tag === this.props.match.params.idOrGamerTag);
          let rank = undefined;
          if (search_results.length !== 0) {
            rank = search_results[0].rank;
          }

          this.setState({
            player: playerResponse,
            isLoaded: true,
            qualifiers,
            events,
            ranking: rank,
            opponents: possibleOpponents,
            opponent,
            playerStats,
            possibleYears,
            currentYear,
          });
        });
      }
    );
  }

  render() {
    let { player, isLoaded, qualifiers, events, ranking, opponents, opponent, playerStats, possibleYears, currentYear } = this.state;
    const profilePicture = player.twitch_user ? (
      <Image
        src={player.twitch_user.profile_image_url}
        className={styles.ProfileImage}
      />
    ) : (
      ""
    );
    if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      const zeroTenPlace = player.times && player.times.zero_ten_place ? player.times.zero_ten_place : 'N/A';
      const zeroTwentyPlace = player.times && player.times.zero_twenty_place ? player.times.zero_twenty_place : 'N/A';
      let date = new Date();
      date.setFullYear( date.getFullYear() - 1 );
      const playerStatsCurrentYear = playerStats.stats[currentYear];
      let matchWins = 0;
      let matchLosses = 0;
      let crownWins = 0;
      let crownLosses = 0;
      const possibleOpponentNames = Array.from(opponents).map((o) => o.gamer_tag);
      if (!possibleOpponentNames.includes(opponent)) {
        opponent = "All";
      }
      events.forEach((event) => {
        event.matches.filter((match) => ((currentYear === 0 || event.name.includes(currentYear)) || (Date.parse(event.start_date) > date && currentYear === -1)) && match.opponent && (opponent === "All" || opponent === match.opponent.gamer_tag)).forEach((match) => {
          crownWins += match.numGamesWon;
          crownLosses += match.opponent.numGamesWon;
          if (match.numGamesWon > match.opponent.numGamesWon) {
            matchWins++;
          } else if (match.numGamesWon < match.opponent.numGamesWon) {
            matchLosses++;
          }
        });
      });
      return (
        <div className={styles.PlayerContainer}>
          <div className={styles.Title}>
            {profilePicture}
            <Typography variant="h4">{ranking ? "#"+ranking : ""} {player.gamer_tag}</Typography>
            <TwitchChip player={player} className={styles.PlayerTwitchChip} />
          </div>
          <Typography variant="h5" className={styles.SectionTitle}>
            <div>Year:&nbsp;
              <Select
                name="year"
                value={currentYear}
                onChange={(event) => {
                  this.props.history.push(
                    `/players/${player.gamer_tag}?year=${event.target.value}&opponent=${opponent}`
                  );
                }}
              >
              {[0, -1].concat(Array.from(possibleYears)
                .sort(function (a, b) {
                  return a > b ? -1 : 1;
                }))
                .map((year) => {
                  return <MenuItem value={year}>{year === 0 ? "All" : (year === -1 ? "Last 12 months" : year)}</MenuItem>;
                })}
              </Select>
            </div>
            <div>Record: {matchWins}-{matchLosses} ({matchWins || matchLosses
              ? (
                (100.0 * (matchWins || 0)) /
                ((matchWins || 0) +
                  (matchLosses || 0))
                ).toFixed(2)
              : "0.00"}
              %)</div>
            <div>Crowns: {crownWins}-{crownLosses} ({crownWins || crownLosses
              ? (
                (100.0 * (crownWins || 0)) /
                ((crownWins || 0) +
                  (crownLosses || 0))
                ).toFixed(2)
              : "0.00"}
              %)</div>
          </Typography>
          <Accordion className={styles.Table} defaultExpanded={false}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Match History</Typography>
            </AccordionSummary>
            &nbsp;&nbsp;&nbsp;&nbsp;vs&nbsp;
            <Select
              name="opponent"
              value={opponent}
              onChange={(event) => {
                this.props.history.push(
                  `/players/${player.gamer_tag}?year=${currentYear}&opponent=${event.target.value}`
                );
              }}>
              {[{gamer_tag:"All"}].concat(Array.from(opponents)
                .filter((opponent) => currentYear === 0 || opponent.years.has(currentYear))
                .sort(function (a, b) {
                  return a.gamer_tag.toLowerCase() < b.gamer_tag.toLowerCase() ? -1 : 1;
                }))
                .map((player) => {
                  return <MenuItem value={player.gamer_tag}>{player.gamer_tag}</MenuItem>;
                })}
            </Select>
          <AccordionDetails>
          <TableContainer component={Paper} className={styles.Table}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Event</TableCell>
                  <TableCell>Matches</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.filter((event) => ((event.name.includes(currentYear) || currentYear === 0) || (Date.parse(event.start_date) > date && currentYear === -1))&& (opponent === "All" || (event.matches.some((elem, index, array) => elem.opponent && elem.opponent.gamer_tag === opponent)))).map((event) => (
                  <TableRow key={event.id}>
                    <TableCell>
                      <Link to={`/events/${event.id}`}>{event.name}</Link>
                    </TableCell>
                    <TableCell>
                      <div className={styles.Matches}>
                        {event.matches.map((match) => {
                          const opponentEl = match.opponent ? (
                            <>
                              <br />({match.seed.seed}) vs. (
                              {match.opponent.seed.seed}){" "}
                              <Link
                                to={`/players/${match.opponent.gamer_tag}?year=${currentYear}`}
                                className={styles.OpponentLink}
                              >
                                {match.opponent.gamer_tag}
                              </Link>
                            </>
                          ) : (
                            ""
                          );
                          return (opponent === "All" || (match.opponent && opponent === match.opponent.gamer_tag)) ? (
                            <div
                              key={match.id}
                              className={`${styles.Match}${
                                match.winner === null
                                  ? ""
                                  : " " +
                                    (match.winner === player.id
                                      ? styles.IsWinner
                                      : styles.IsLoser)
                              }`}
                            >
                              <div className={styles.RoundInfo}>
                                <span className={styles.RoundName}>
                                  {match.round.name}
                                </span>
                                {opponentEl}
                              </div>
                              <div className={styles.MatchInfo}>
                                {match.winner === null
                                  ? ""
                                  : match.winner === player.id
                                  ? "W "
                                  : "L "}
                                {match.numGamesWon}-
                                {match.opponent
                                  ? match.opponent.numGamesWon
                                  : 0}
                              </div>
                            </div>
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </AccordionDetails>
          </Accordion>
          <p/>
          <Accordion className={styles.Table} defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Performance by Round</Typography>
            </AccordionSummary>
          <AccordionDetails>
          <TableContainer component={Paper} className={styles.Table}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Levels</TableCell>
                  <TableCell align="right">Average</TableCell>
                  <TableCell align="right">Best</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(Object.getOwnPropertyNames(playerStatsCurrentYear.roundGames))
                  .sort(function (a, b) {
                    return parseInt(a.split("-")[0])*parseInt(a.split("-")[1]) - parseInt(b.split("-")[0])*parseInt(b.split("-")[1]);
                  })
                  .map((roundName) => {
                    return <TableRow key={roundName}>
                      <TableCell>
                        {roundName}
                      </TableCell>
                      <TableCell align="right">
                        {secondsToTime(playerStatsCurrentYear.roundTimes[roundName]/playerStatsCurrentYear.roundGames[roundName])}
                      </TableCell>
                      <TableCell align="right">
                        {playerStatsCurrentYear.roundBests[roundName] < 10000 ? secondsToTime(playerStatsCurrentYear.roundBests[roundName]) : ""}
                      </TableCell>
                    </TableRow>;
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          </AccordionDetails>
          </Accordion>
          <p/>
          <Accordion className={styles.Table} defaultExpanded={false}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Qualifiers</Typography>
            </AccordionSummary>
          <AccordionDetails>
          <TableContainer component={Paper} className={styles.Table}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Event</TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center" colSpan="2">
                    Score
                  </TableCell>
                  <TableCell align="right">Time to Last Level</TableCell>
                  <TableCell align="right">Previous Attempt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {qualifiers.filter((qualifier) => (currentYear === 0 || qualifier.event.name.includes(currentYear)) || (Date.parse(qualifier.event.start_date) > date && currentYear === -1)).map((qualifier) => {
                  let pbBadge = <></>
                  if (qualifier.pb) {
                    pbBadge = <>
                      <Chip style={{"backgroundColor": "#fffde7"}} size="small" label="PB" avatar={<Avatar src="/badge.png" style={{"backgroundColor": "#fdd835"}}/>}/>
                    </>
                  }

                  return (
                    <TableRow key={qualifier.id}>
                      <TableCell>
                        <Link to={`/events/${qualifier.event_id}`}>
                          {qualifier.event.name}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {pbBadge}
                      </TableCell>
                      <TableCell align="right">
                        {scoreToVirusScore(qualifier.score, true)}
                      </TableCell>
                      <TableCell align="left">{qualifier.score}</TableCell>
                      <TableCell align="right">
                        {secondsToTime(qualifier.time_to_last_level_seconds)}
                      </TableCell>
                      <TableCell align="right">
                        {qualifier.lastQualifyingAttempt
                          ? scoreToVirusScore(
                              qualifier.lastQualifyingAttempt.score, true
                            )
                          : ""}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          </AccordionDetails>
          </Accordion>
          <p/>
          <Accordion className={styles.Table} defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Personal Bests (speedrun.com)</Typography>
            </AccordionSummary>
          <AccordionDetails>
          <TableContainer component={Paper} className={styles.Table}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Category</TableCell>
                  <TableCell>Place</TableCell>
                  <TableCell align="right">Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    0-10
                  </TableCell>
                  <TableCell className={styles['Place' + zeroTenPlace]}>
                    {zeroTenPlace}
                  </TableCell>
                  <TableCell>
                    {player.times && player.times.zero_ten_time_seconds ? secondsToTime(player.times.zero_ten_time_seconds) : 'N/A'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    0-20
                  </TableCell>
                  <TableCell className={styles['Place' + zeroTwentyPlace]}>
                    {zeroTwentyPlace}
                  </TableCell>
                  <TableCell>
                    {player.times && player.times.zero_twenty_time_seconds ? secondsToTime(player.times.zero_twenty_time_seconds) : 'N/A'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          </AccordionDetails>
          </Accordion>
        </div>
      );
    }
  }
}

export default Player;
