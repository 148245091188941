import Table from '@mui/joy/Table';
import {styled} from '@mui/joy/styles';
import {PropsWithChildren} from 'react';

import {Link} from './Link';
import {PersonalBestBadge} from './PersonalBestBadgeGraphql';
import {PlayerImage} from './PlayerImageGraphql';
import {QualifierScoreTrend} from './QualifierScoreTrendGraphql';

import {Qualifier} from './event_utils';
import {getRenderableQualifiers} from './qualifiers_utils';

import styles from './QualifiersGraphql.module.scss';

interface Props {
  qualifiers: Qualifier[];
  startDate: string;
}

export function Qualifiers(props: Props) {
  const renderableQualifiers = getRenderableQualifiers(
    props.qualifiers,
    props.startDate,
  );
  const TableRow = styled(TableRowBase)(({theme, isDrop}) => ({
    backgroundColor: isDrop ? theme.palette.danger.softBg : '',
    '--TableRow-hoverBackground': isDrop ? theme.palette.danger.softBg : '',
  }));
  return (
    <div className={styles.Container}>
      <Table
        className={styles.Table}
        size="sm"
        hoverRow
        sx={(theme) => ({
          '--TableRow-hoverBackground': theme.palette.background.level1,
        })}>
        <thead>
          <tr>
            <th className={styles.CellRight}>Seed</th>
            <th className={styles.CellColspan} colSpan={2}>
              Player
            </th>
            <th
              className={`${styles.CellCenter} ${styles.CellColspan}`}
              colSpan={3}>
              Score
            </th>
            <th className={styles.CellRight}>Time to Last Level</th>
            <th className={styles.CellRight}>Previous Attempt</th>
          </tr>
        </thead>
        <tbody>
          {renderableQualifiers.map((renderableQualifier) => (
            <TableRow
              key={renderableQualifier.key}
              isDrop={renderableQualifier.isDrop}>
              <td className={styles.CellRight}>{renderableQualifier.seed}</td>
              <td>
                <div className={styles.PlayerInfo}>
                  <PlayerImage player={renderableQualifier.player} />
                  <Link to={`/players/${renderableQualifier.player.gamerTag}`}>
                    {renderableQualifier.player.gamerTag}
                  </Link>
                </div>
              </td>
              <td>
                {renderableQualifier.isPersonalBestScore ? (
                  <PersonalBestBadge
                    previousPersonalBestScore={
                      renderableQualifier.previousPersonalBestScore
                    }
                  />
                ) : (
                  <></>
                )}
              </td>
              <td className={styles.CellRight}>
                {renderableQualifier.virusScore}
              </td>
              <td className={styles.CellCenter}>{renderableQualifier.score}</td>
              <td>
                <QualifierScoreTrend
                  score={renderableQualifier.score}
                  previousQualifierScore={
                    renderableQualifier.previousQualifierScore
                  }
                />
              </td>
              <td className={styles.CellRight}>
                {renderableQualifier.timeToLastLevel}
              </td>
              <td className={styles.CellRight}>
                {renderableQualifier.previousAttemptVirusScore}
              </td>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

interface TableRowProps {
  isDrop: boolean;
}

function TableRowBase(props: PropsWithChildren<TableRowProps>) {
  return <tr {...props}>{props.children}</tr>;
}
