import React from "react";

import styles from "./Image.module.scss";

class Image extends React.Component {
  render() {
    const { alt, isPixelated, src, title } = this.props;
    let className = "";
    if (this.props.className) {
      className = this.props.className;
    }
    if (isPixelated) {
      className += ` ${styles.Pixelated}`;
    }
    return <img src={src} alt={alt} className={className} title={title} />;
  }
}

export default Image;
