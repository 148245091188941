import MuiLink from '@mui/joy/Link';
import {PropsWithChildren} from 'react';
import {Link as ReactRouterLink} from 'react-router-dom';

interface Props {
  to: string;
}

export function Link(props: PropsWithChildren<Props>) {
  return (
    <MuiLink to={props.to} component={ReactRouterLink} underline="always">
      {props.children}
    </MuiLink>
  );
}
