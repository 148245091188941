import DarkModeIcon from '@mui/icons-material/DarkMode';
import IconButton from '@mui/joy/IconButton';
import LightModeIcon from '@mui/icons-material/LightMode';
import {useColorScheme} from '@mui/joy/styles';

export function DarkLightModeIcon() {
  const {mode, setMode} = useColorScheme();
  return (
    <IconButton
      variant="soft"
      onClick={() => setMode(mode === 'dark' ? 'light' : 'dark')}>
      {mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
    </IconButton>
  );
}
