export class ApiError extends Error {
  private readonly errors: string[];

  constructor(errors: string[] = [], ...params: any[]) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }
    this.name = "ApiError";
    this.errors = errors;
  }
}