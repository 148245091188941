import {Game, Match, MatchPlayer, Round, Seed} from './event_utils';
import {secondsToTime} from './utils/time';
import {scoreToVirusScore} from './utils/virus_score';

interface RenderableBracket {
  rounds: RenderableRound[];
}

export interface RenderableRound {
  order: number;
  title: string;
  subtitle: string;
  matches: RenderableMatch[];
}

export interface RenderableMatch {
  matchNumber: number;
  player1?: RenderablePlayer;
  player2?: RenderablePlayer;
}

export interface RenderablePlayer {
  isMatchWinner: boolean;
  seed?: number;
  gamerTag: string;
  gamesWon: RenderableGame[];
}

export interface RenderableGame {
  gameNumber: number;
  winningPlayerGamerTag: string;
  winningPlayerStats: string;
  losingPlayerGamerTag: string;
  losingPlayerStats: string;
}

export function getRenderableBracket(
  rounds: Round[],
  seeds: Seed[],
): RenderableBracket {
  return {
    rounds: [...rounds]
      .sort((a, b) => a.order - b.order)
      .map((round) => getRenderableRound(round, seeds)),
  };
}

function getRenderableRound(round: Round, seeds: Seed[]): RenderableRound {
  return {
    order: round.order,
    title: round.name ?? '',
    subtitle: `Level ${round.levelStart}-${round.levelEnd}, Best of ${
      round.numWinsRequired * 2 - 1
    }`,
    matches: [...round.matches]
      .sort((a, b) => a.matchNumber - b.matchNumber)
      .map((match) => getRenderableMatch(match, seeds, round)),
  };
}

function getRenderableMatch(
  match: Match,
  seeds: Seed[],
  round: Round,
): RenderableMatch {
  const games = [...match.games].sort((a, b) => a.gameNumber - b.gameNumber);
  return {
    matchNumber: match.matchNumber,
    player1: getRenderablePlayer(
      match.player1,
      match.player2,
      1,
      games,
      seeds,
      round,
    ),
    player2: getRenderablePlayer(
      match.player2,
      match.player1,
      2,
      games,
      seeds,
      round,
    ),
  };
}

function getRenderablePlayer(
  player: MatchPlayer,
  otherPlayer: MatchPlayer,
  playerNumber: number,
  games: Game[],
  seeds: Seed[],
  round: Round,
): RenderablePlayer | undefined {
  if (!player) {
    return undefined;
  }
  const gamesWon = getGamesWon(playerNumber, games);
  return {
    isMatchWinner: isMatchWinner(otherPlayer, gamesWon, round),
    seed: getSeed(player, seeds),
    gamerTag: player.gamerTag,
    gamesWon: gamesWon.map((game) =>
      getRenderableGame(
        game,
        player,
        otherPlayer,
        playerNumber,
        round.levelStart,
      ),
    ),
  };
}

function isMatchWinner(
  otherPlayer: MatchPlayer,
  gamesWon: Game[],
  round: Round,
): boolean {
  if (round.order === 1 && !otherPlayer) {
    return true;
  }
  return gamesWon.length >= round.numWinsRequired;
}

function getSeed(
  player: NonNullable<MatchPlayer>,
  seeds: Seed[],
): number | undefined {
  return seeds.find((seed) => seed.player?.id === player.id)?.seed;
}

function getGamesWon(playerNumber: number, games: Game[]): Game[] {
  return games.filter((game) => isGameWin(playerNumber, game));
}

function isGameWin(playerNumber: number, game: Game): boolean {
  if (game.winningPlayerNumberForced === playerNumber) {
    return true;
  } else if (game.winningPlayerNumberForced !== 0) {
    return false;
  }
  const player1TimeSeconds = game.player1TimeSeconds ?? 0;
  const player2TimeSeconds = game.player2TimeSeconds ?? 0;
  if (playerNumber === 1) {
    return isGameWinForPlayer(player1TimeSeconds, player2TimeSeconds);
  } else {
    return isGameWinForPlayer(player2TimeSeconds, player1TimeSeconds);
  }
}

function isGameWinForPlayer(
  playerTimeSeconds: number,
  otherPlayerTimeSeconds: number,
): boolean {
  return (
    otherPlayerTimeSeconds === 0 ||
    (playerTimeSeconds > 0 && playerTimeSeconds < otherPlayerTimeSeconds)
  );
}

function getRenderableGame(
  game: Game,
  player: MatchPlayer,
  otherPlayer: MatchPlayer,
  playerNumber: number,
  levelStart: number,
): RenderableGame {
  return {
    gameNumber: game.gameNumber,
    winningPlayerGamerTag: player?.gamerTag ?? '',
    winningPlayerStats: getPlayerGameStatsByNumber(
      game,
      playerNumber,
      levelStart,
    ),
    losingPlayerGamerTag: otherPlayer?.gamerTag ?? '',
    losingPlayerStats: getPlayerGameStatsByNumber(
      game,
      (playerNumber % 2) + 1,
      levelStart,
    ),
  };
}

function getPlayerGameStatsByNumber(
  game: Game,
  playerNumber: number,
  levelStart: number,
): string {
  if (game.winningPlayerNumberForced !== 0) {
    return '';
  }
  if (playerNumber === 1) {
    return getPlayerGameStats(
      game.player1Score,
      game.player1TimeSeconds,
      levelStart,
    );
  } else if (playerNumber === 2) {
    return getPlayerGameStats(
      game.player2Score,
      game.player2TimeSeconds,
      levelStart,
    );
  }
  return '';
}

function getPlayerGameStats(
  score: number | undefined,
  timeSeconds: number | undefined,
  levelStart: number,
): string {
  if (score) {
    return scoreToVirusScore(score, false /* shouldPad */, levelStart);
  }
  if (timeSeconds) {
    return secondsToTime(timeSeconds);
  }
  return '';
}
