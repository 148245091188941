import React, { useLayoutEffect, useState } from "react";
import { Switch, Route, Link, useLocation } from "react-router-dom";
import Alert from "@mui/lab/Alert";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TimerIcon from "@mui/icons-material/Timer";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import EventIcon from "@mui/icons-material/Event";
import FacebookIcon from "@mui/icons-material/Facebook";
import GavelIcon from "@mui/icons-material/Gavel";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Snackbar, Toolbar, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { StyledEngineProvider } from "@mui/material/styles";
import {CssVarsProvider} from '@mui/joy/styles';

import Admin from "./Admin";
import AdminButton from "./AdminButton";
import AdminEdit from "./AdminEdit";
import AdminRoute from "./AdminRoute";
import Event from "./Event";
import {Event as EventGraphql} from './EventGraphql';
import {Events} from './Events';
import IfAdmin from "./IfAdmin";
import Image from "./Image";
import LoginButton from "./LoginButton";
import Logout from "./Logout";
import Player from "./Player";
import QualRecords from "./QualRecords";
import Standings from "./Standings";
import HotTimes from "./HotTimes";
import Stats from "./Stats";
import Session from "./Session";
import {apiFetch} from "./utils/api_fetch";

import styles from "./App.module.scss";

import { ReactComponent as DiscordIcon } from "./discord.svg";
import { ReactComponent as TwitchIcon } from "./twitch.svg";
import { ReactComponent as YouTubeIcon } from "./youtube.svg";
import { ReactComponent as AnalyticsIcon } from "./analytics.svg";

const MENU_ITEMS = [
  {
    icon: <EventIcon />,
    label: "Events",
    href: "/",
  },
  {
    icon: <EmojiEventsIcon />,
    label: "Standings",
    href: "/standings",
  },
  {
    icon: <TimerIcon />,
    label: "Qualifiers",
    href: "/qualrecords",
  },
  {
    icon: <WhatshotIcon />,
    label: "Hot Times",
    href: "/hottimes",
  },
  {
    icon: (
      <SvgIcon>
        <AnalyticsIcon />
      </SvgIcon>
    ),
    label: "Stats",
    href: "/stats",
  },
  {},
  {
    icon: <GavelIcon />,
    label: "Rules",
    href:
      "https://docs.google.com/document/d/1PPhGh62cLExkklhmyx5f22Gw1fCTA_x2seCrnClPvtA",
  },
  {
    icon: <DashboardIcon />,
    label: "Stencil",
    href: "/Stencil_2.0.png",
    isExternal: true,
  },
  {},
  {
    icon: (
      <SvgIcon>
        <DiscordIcon />
      </SvgIcon>
    ),
    label: "Discord",
    href: "https://discord.gg/fdEJXzT",
  },
  {
    icon: (
      <SvgIcon>
        <TwitchIcon />
      </SvgIcon>
    ),
    label: "Twitch",
    href: "https://www.twitch.tv/TheDrMC",
  },
  {
    icon: (
      <SvgIcon>
        <YouTubeIcon />
      </SvgIcon>
    ),
    label: "YouTube",
    href: "https://www.youtube.com/c/DrMarioChampionship",
  },
  {
    icon: (
      <SvgIcon>
        <TwitterIcon />
      </SvgIcon>
    ),
    label: "Twitter",
    href: "https://www.twitter.com/drmchampionship",
  },
  {
    icon: (
      <SvgIcon>
        <FacebookIcon />
      </SvgIcon>
    ),
    label: "Facebook",
    href: "https://www.facebook.com/groups/134719510462547",
  },
  {
    icon: (
      <SvgIcon>
        <ShoppingCartIcon />
      </SvgIcon>
    ),
    label: "Merch",
    href: "https://thedrmc.creator-spring.com",
  },
];

const App = (props) => {
  const [user, setUser] = useState(null);
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [hasFetchedUser, setHasFetchedUser] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const location = useLocation();

  useLayoutEffect(() => {
    async function fetchUser() {
      const sessionId = window.localStorage.getItem("sessionId");
      let user = null;
      if (sessionId !== null && !isFetchingUser) {
        setIsFetchingUser(true);
        try {
          user = await apiFetch("user");
        } catch (error) {}
        setIsFetchingUser(false);
      }
      setUser(user);
      setHasFetchedUser(true);
    }
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const adminProps = { user, isFetchingUser, hasFetchedUser };

  const showSnackbar = (message, severity = "error") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <StyledEngineProvider injectFirst>
        <Box>
          <AppBar position="static" className={styles.Header}>
            <Toolbar>
              <IconButton
                edge="start"
                className={styles.MenuButton}
                onClick={() => setDrawerOpen(true)}
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
              >
                <div
                  className={styles.DrawerList}
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                >
                  <List>
                    {MENU_ITEMS.map((menuItem, i) => {
                      if (!menuItem.label) {
                        return <Divider className={styles.Divider} key={i} />;
                      }
                      const isInternal = menuItem.isExternal
                        ? false
                        : menuItem.href.startsWith("/");
                      const component = isInternal ? Link : "a";
                      const linkProps = isInternal
                        ? { to: menuItem.href }
                        : {
                            href: menuItem.href,
                            target: "_blank",
                            noreferrer: null,
                          };
                      return (
                        <ListItem
                          key={i}
                          button
                          component={component}
                          {...linkProps}
                        >
                          <ListItemIcon>{menuItem.icon}</ListItemIcon>
                          <ListItemText primary={menuItem.label} />
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              </Drawer>
              <Link to="/" className={styles.TitleLink}>
                <Typography variant="h5" className={styles.Title}>
                  <Image src="/logo.png" className={styles.TitleImage} />
                  <span className={styles.TitleText}>Speed Monthly</span>
                </Typography>
              </Link>
              <IfAdmin user={user}>
                <AdminButton />
              </IfAdmin>
              <LoginButton user={user} />
            </Toolbar>
          </AppBar>
          <Box m={2}>
            <Switch>
              <Route path="/session" component={Session} />
              <Route path="/logout" component={Logout} />
              <Route path="/qualrecords" component={QualRecords} />
              <Route path="/standings/:year?/:month?" component={Standings} />
              <Route path="/hottimes/" component={HotTimes} />
              <Route path="/stats/:year?" component={Stats} />
              <Route
                path="/events/:idOrSlug"
                render={(props) => (
                  <Event {...props} user={user} showSnackbar={showSnackbar} />
                )}
              />
              <Route
                path="/events-graphql/:slug"
                render={(props) => (
                  <CssVarsProvider>
                    <EventGraphql slug={props.match.params.slug} />
                  </CssVarsProvider>
                )}
              />
              <Route path="/players/:idOrGamerTag" component={Player} />
              <AdminRoute
                exact
                path="/admin"
                component={Admin}
                {...adminProps}
              />
              <AdminRoute
                path="/admin/:table"
                component={AdminEdit}
                {...adminProps}
              />
              <Route path="/" component={Events} />
            </Switch>
          </Box>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert
              onClose={handleClose}
              severity={snackbarSeverity}
              variant="filled"
              elevation={6}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
      </StyledEngineProvider>
    </LocalizationProvider>
  );
};

export default App;
